import { Injectable } from '@angular/core';
import * as Sentry from '@sentry/angular-ivy';

@Injectable({
  providedIn: 'root',
})
export class SentryIoService {
  addBreadcrumb(action: string): void {
    Sentry.addBreadcrumb({
      category: 'action',
      message: action,
      type: 'User Action',
    });
  }
}
