/** Video call opened **/
export class VideoCallChangeIsOpened {
  static readonly type = '[VideoCalls] Change isOpened';
  constructor(public payload) {}
}

/** Video call set last sessionIds **/
export class VideoCallSetLastSessions {
  static readonly type = '[VideoCalls] Set Last Sessions';
  constructor() {}
}

/** Video call set last sessionId **/
export class VideoCallSetLastSessionId {
  static readonly type = '[VideoCalls] Set Last sessionId';
  constructor(public payload) {}
}

/** Video call send action **/
export class VideoCallsSendAction {
  static readonly type = '[VideoCalls] Send Action';
  constructor(public payload) {}
}

/** Set chat id by video call invite **/
export class VideoCallsSetInvitedRoomId {
  static readonly type = '[VideoCalls] SetInvitedChatId';
  constructor(public payload) {}
}

/** Set chat id by video call invite **/
export class VideoCallsGetInviteLink {
  static readonly type = '[VideoCalls] GetInviteLink';
  constructor(public payload) {}
}
