<ng-container *transloco="let t; read: 'modals.chat-group-create'">
  <div class="modal-header">
    <h5 class="modal-title">{{ t('create-group-chat') }}</h5>
    <button type="button" class="close" aria-label="Close" (click)="close(chat_group_form)">
      <app-svg name="times" width="10" height="10" class="svg-icon" aria-hidden="true"></app-svg>
    </button>
  </div>

  <div class="modal-body">
    <section id="ngx" [class.p-2]="platform !== 'web'">
      <p class="title">{{ t('add-details') }}</p>
      <p class="subtitle">{{ t('information') }}</p>
      <form #chat_group_form="ngForm" class="editForm" novalidate>
        <div class="form-group">
          <label
            class="form-control-label"
            for="chatGroupName"
            t-id="chat-group-create-name-group"
            >{{ t('group-chat-name') }}</label
          >
          <input
            class="form-control chat-group-name"
            #chatGroupName="ngModel"
            id="chatGroupName"
            name="chatGroupName"
            placeholder="{{ t('placeholder-enter-chat-name') }}"
            type="text"
            required
            ngModel
            [appFocus]="platform === 'web'"
          />
          <small
            class="form-text danger"
            *ngIf="!chatGroupName.valid && (chatGroupName.dirty || chatGroupName.touched)"
          >
            {{ t('field-is-required') }}
          </small>
        </div>

        <div class="form-group">
          <label class="form-control-label" for="chatGroupMembers">{{ t('chat-members') }}</label>
          <ng-select
            t-id="chat-group-create-chat-members"
            id="chatGroupMembers"
            name="chatGroupMembers"
            class="chat-group-members"
            #chatGroupMembers="ngModel"
            bindLabel="userName"
            bindValue="_id"
            appendTo="body"
            placeholder="{{ t('placeholder-search-user') }}"
            [items]="users"
            [multiple]="true"
            [searchable]="true"
            required
            ngModel
          >
            <ng-template ng-label-tmp let-item="item" let-clear="clear">
              <div class="d-flex align-items-center">
                <app-avatar
                  [userId]="item._id"
                  [height]="24"
                  [width]="24"
                  [statusSize]="9"
                ></app-avatar>
                <div class="ml-1">
                  <div>{{ item.userName }}</div>
                </div>
                <div class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</div>
              </div>
            </ng-template>

            <ng-template ng-option-tmp let-item="item" let-index="index" id="guests_select">
              <div class="d-flex align-items-center">
                <app-avatar
                  [userId]="item._id"
                  [height]="24"
                  [width]="24"
                  [statusSize]="9"
                ></app-avatar>
                <div class="ml-1">
                  <b>{{ item.userName }}</b>
                </div>
              </div>
            </ng-template>
          </ng-select>
          <small
            class="form-text danger"
            *ngIf="!chatGroupMembers.valid && (chatGroupMembers.dirty || chatGroupMembers.touched)"
          >
            {{ t('field-is-required') }}
          </small>
        </div>
      </form>
    </section>
  </div>

  <div class="modal-footer">
    <div class="form-group d-flex align-items-center justify-content-end m-0">
      <button type="button" class="btn btn-subtle" (click)="close(chat_group_form)">
        {{ t('btn-cancel') }}
      </button>
      <button
        t-id="chat-group-create-button"
        type="button"
        uiSref="work"
        class="btn btn-solid create-btn"
        [disabled]="isSubmitting"
        [disabled]="!chat_group_form.valid"
        (click)="addChatGroup(chat_group_form)"
      >
        {{ t('btn-create-chat') }}
      </button>
    </div>
  </div>
</ng-container>
