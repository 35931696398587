import { ChangeDetectorRef, Component, OnDestroy, OnInit, forwardRef } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { Actions, Store } from '@ngxs/store';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { WrappedSocket } from 'ngx-socket-io/src/socket-io.service';
import { TranslocoService, TranslocoDirective } from '@ngneat/transloco';

import { ChatsDbDto } from '../../api/models/chats-db-dto';
import { SocketsService } from '../../shared/services/sockets.service';
import { SpaceGetUsersListForCreateChatGroup } from '../../shared/store/actions/spaces.action';
import { ChatsState } from '../../shared/store/states/chats.state';
import { ConfirmAlert } from '../../shared/alerts/alerts';
import { UsersDbDto } from '../../api/models/users-db-dto';
import { AuthState } from '../../shared/store/states/auth.state';
import { TenantsState } from '../../shared/store/states/tenants.state';
import { SpacesState } from '../../shared/store/states/spaces.state';
import { NgIf } from '@angular/common';
import { AvatarComponent } from '../../standalone/components/avatar/avatar.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { SvgComponent } from '../../shared/svgs/svg/svg.component';

@Component({
  selector: 'app-chat-group-members-modal',
  templateUrl: './chat-group-members.component.html',
  styleUrls: ['./chat-group-members.component.scss'],
  standalone: true,
  imports: [
    TranslocoDirective,
    SvgComponent,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    forwardRef(() => AvatarComponent),
    NgIf,
  ],
})
export class ChatGroupMembersModalComponent implements OnInit, OnDestroy {
  platform = 'web';
  socket: WrappedSocket;
  destroy$: Subject<void> = new Subject<void>();
  usersSubs$: Subscription;
  membersForm: FormGroup;
  chatId: string;
  spaceId: string;
  chatMembers: string[];
  users: any[];
  chat: ChatsDbDto;
  currentUser: UsersDbDto;
  initialChatMembers: string[];
  isAllowToRemoveMember: boolean;
  private space: any;

  constructor(
    private actions: Actions,
    private activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private store: Store,
    private toastr: ToastrService,
    private socketsService: SocketsService,
    public cdr: ChangeDetectorRef,
    private translocoService: TranslocoService,
  ) {
    this.socket = this.socketsService.get();
  }

  /**
   * Subscribe to display modal action
   */
  ngOnInit() {
    this.membersForm = this.formBuilder.group({
      members: [this.chatMembers || [], Validators.required],
    });
    this.isAllowToRemoveMember = false;

    this.getUsersList();

    this.socket.on('chats:editGroupMembers:response', (res) => {
      if (res.success) {
        this.toastr.success(
          this.translocoService.translate('toastr.message-group-chat-members-changed'),
          this.translocoService.translate('toastr.title-success'),
        );
        this.activeModal.close();
      } else {
        this.toastr.error(
          this.translocoService.translate('toastr.message-err-group-chat-members-not-changed'),
          this.translocoService.translate('toastr.title-error'),
        );
      }
    });
  }

  ngOnDestroy() {
    this.socket.removeListener('chats:editGroupMembers:response');
    this.usersSubs$?.unsubscribe();
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * Close modal handler
   */
  close() {
    if (this.membersForm.dirty) {
      console.log(this.membersForm.dirty);
      ConfirmAlert(null, {
        subject: this.translocoService.translate('alert.close-modal-subject'),
        text: this.translocoService.translate('alert.close-modal-text'),
        showCancelButton: true,
        cancelButtonText: this.translocoService.translate('alert.close-modal-btn-close'),
        showDenyButton: true,
        denyButtonText: this.translocoService.translate('alert.close-modal-btn-discard'),
        denyButtonClass: 'btn-subtle',
        confirmButtonText: this.translocoService.translate('alert.close-modal-btn-save'),
        confirmButtonClass: 'btn-solid',
        platform: this.platform,
      }).then(
        (result) => {
          if (result === 'isConfirmed') {
            this.changeChatGroupMembers(this.membersForm);
          }
          if (result === 'isDenied') {
            this.activeModal.close();
          }
        },
        () => {},
      );
    } else {
      this.activeModal.close();
    }
  }

  changeChatGroupMembers(form) {
    if (form.value.members.length > 0) {
      this.socket.emit('chats:editGroupMembers', {
        chatId: this.chatId,
        members: form.value.members,
      });
    }
  }

  getUsersList(): void {
    this.chat = this.store
      .selectSnapshot(ChatsState.getChats)
      .find((chat) => chat._id === this.chatId);

    this.chatMembers = this.store
      .selectSnapshot(ChatsState.getChatMembers)(this.chatId)
      ?.map((user) => user.userId);

    this.currentUser = this.store.selectSnapshot(AuthState.getUser);
    const isChatAdmin = this.chat?.userId === this.currentUser?._id;

    const space = (this.space = this.store.selectSnapshot(SpacesState.getSpace)(this.spaceId));
    const isSpaceOwner = space?.ownerId === this.currentUser?._id;
    let hasTenantOwnerRole = false,
      hasSpaceLeaderRole = false,
      isSpaceMember = false;

    if (this.currentUser?.roles && Array.isArray(this.currentUser.roles)) {
      for (const role of this.currentUser?.roles) {
        if (role.object === 'tenants' && role.roleName === 'Owner') {
          hasTenantOwnerRole = true;
          break;
        }

        if (role.object === 'spaces' && role.roleName === 'Space Leader') {
          hasSpaceLeaderRole = true;
          break;
        }
      }
    }

    this.usersSubs$ = this.store
      .dispatch(new SpaceGetUsersListForCreateChatGroup({ id: this.spaceId }))
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.users = res?.Spaces.usersForGroup.map((user) => {
          if (this.currentUser?.roles && Array.isArray(this.currentUser.roles)) {
            this.currentUser?.roles.forEach((role) => {
              if (
                role.object === 'spaces' &&
                role.roleName === 'Space Member' &&
                role.objectId === this.spaceId
              ) {
                isSpaceMember = true;
              }
            });
          }

          if (isSpaceMember) {
            if (!isChatAdmin) {
              return { ...user, disabled: true };
            }
          } else {
            if (
              !hasTenantOwnerRole &&
              !isSpaceOwner &&
              !isChatAdmin &&
              user._id !== this.currentUser._id &&
              !hasSpaceLeaderRole
            ) {
              // return {...user, disabled: true};
            }
          }

          return user;
        });

        this.membersForm.controls['members'].setValue(this.chatMembers);
        if (!this.initialChatMembers) {
          this.initialChatMembers = this.chatMembers;
        }
      });
  }

  get isActiveBtn() {
    return (
      JSON.stringify(this.initialChatMembers?.sort()) !==
      JSON.stringify(this.membersForm.controls['members']?.value?.sort())
    );
  }

  canRemoveItem(item: any): boolean {
    const userRoles = this.currentUser?.roles;

    if (!userRoles || !Array.isArray(userRoles)) {
      return true;
    }

    for (const role of userRoles) {
      if (
        role.object === 'spaces' &&
        role.roleName === 'Space Member' &&
        role.objectId === this.spaceId &&
        String(this.chat?.userId) !== String(this.currentUser?._id) &&
        String(item._id) !== String(this.currentUser?._id)
      ) {
        return false;
      }
    }

    return true;
  }
}
