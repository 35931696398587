import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { WebhookStateModel } from '../models/WebhookState';
import {
  ClearCreatedWebhook,
  WebhookCreate,
  WebhookDelete,
  WebhooksByChatIdGet,
  WebhookUpdate,
} from '../actions/webhook.actions';
import { WebhooksService } from '../../../api/services/webhooks.service';
import { tap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@State<WebhookStateModel>({
  name: 'Webhooks',
  defaults: {
    webhooksByChatId: {},
    createdWebhook: {},
  },
})
@Injectable()
export class WebhooksState {
  /**
   * get webhooks by chat id map
   * @param  {WebhookStateModel} state
   */
  @Selector()
  static getWebhooksByChatIdMap(state: WebhookStateModel) {
    return state.webhooksByChatId;
  }

  /**
   * get webhooks list by chat id
   * @param  {WebhookStateModel} state
   */
  @Selector()
  static getWebhooksListByChatId(state: WebhookStateModel) {
    return (chatId) => state.webhooksByChatId[chatId];
  }

  /**
   * get createdWebhook
   * @param  {WebhookStateModel} state
   */
  @Selector()
  static getCreatedWebhook(state: WebhookStateModel) {
    return state.createdWebhook;
  }

  constructor(
    private store: Store,
    private webhooksService: WebhooksService,
    protected toastrService: ToastrService,
    private translocoService: TranslocoService,
  ) {}

  /**
   * Get webhooks by chat id action handler
   * @param  {patchState}: StateContext<WebhookStateModel>
   * @param  {WebhooksByChatIdGet} action
   */
  @Action(WebhooksByChatIdGet)
  webhooksByChatIdGet(
    { getState, patchState }: StateContext<WebhookStateModel>,
    action: WebhooksByChatIdGet,
  ) {
    return this.webhooksService.webhookChatByIdGet({ id: action.chatId }).pipe(
      tap(
        (res) => {
          if (res) {
            const webhooksByChatId = { ...getState().webhooksByChatId };
            webhooksByChatId[action.chatId] = res;
            patchState({ webhooksByChatId });
          }
        },
        (err) => {
          throw err.error;
        },
      ),
    );
  }

  /**
   * Create webhook
   * @param  {patchState}: StateContext<WebhookStateModel>
   * @param  {WebhookCreate} action
   */
  @Action(WebhookCreate)
  webhookCreate({ getState, patchState }: StateContext<WebhookStateModel>, action: WebhookCreate) {
    const body = action.payload;
    return this.webhooksService.webhookCreate({ body }).pipe(
      tap(
        (res) => {
          this.toastrService.success(
            this.translocoService.translate('toastr.webhook-successfully-created'),
            this.translocoService.translate('toastr.title-success'),
          );
          const webhooksByChatId = { ...getState().webhooksByChatId };
          webhooksByChatId[res.chatId] = [...webhooksByChatId[res.chatId], res];
          patchState({ webhooksByChatId, createdWebhook: res });
          return res;
        },
        (err) => {
          this.toastrService.error(
            err.message,
            this.translocoService.translate('toastr.title-error'),
          );
          throw err.error;
        },
      ),
    );
  }

  /**
   * Delete webhook
   * @param  {patchState}: StateContext<WebhookStateModel>
   * @param  {WebhookDelete} action
   */
  @Action(WebhookDelete)
  webhookDelete({ getState, patchState }: StateContext<WebhookStateModel>, action: WebhookDelete) {
    return this.webhooksService.webhookDelete({ id: action.payload.id }).pipe(
      tap(
        (res) => {
          if (res?.success) {
            this.toastrService.success(
              this.translocoService.translate('toastr.webhook-successfully-deleted'),
              this.translocoService.translate('toastr.title-success'),
            );
            const webhooksByChatId = { ...getState().webhooksByChatId };
            const webhooks = webhooksByChatId[action.payload.chatId];
            const webhooksFiltered = webhooks.filter((w) => w._id !== action.payload.id);
            webhooksByChatId[action.payload.chatId] = [...webhooksFiltered];
            return patchState({ webhooksByChatId });
          }
        },
        (err) => {
          this.toastrService.error(
            err.message,
            this.translocoService.translate('toastr.title-error'),
          );
          throw err.error;
        },
      ),
    );
  }

  /**
   * Update webhook
   * @param  {patchState}: StateContext<WebhookStateModel>
   * @param  {WebhookUpdate} action
   */
  @Action(WebhookUpdate)
  webhookUpdate({ getState, patchState }: StateContext<WebhookStateModel>, action: WebhookUpdate) {
    const body = action.payload;
    return this.webhooksService.webhookUpdate({ id: body._id, body }).pipe(
      tap(
        (res) => {
          this.toastrService.success(
            this.translocoService.translate('toastr.webhook-successfully-updated'),
            this.translocoService.translate('toastr.title-success'),
          );
          const webhooksByChatId = { ...getState().webhooksByChatId };
          const webhooks = [...webhooksByChatId[action.payload.chatId]];
          const webhooksFiltered = webhooks.filter((w) => w._id !== res._id);
          const webhookOld = webhooks.find((w) => w._id === res._id);
          const webhookToUpdate = {
            ...res,
            avatarUrl: webhookOld.avatarUrl,
          };
          webhooksByChatId[res.chatId] = [...webhooksFiltered, webhookToUpdate];
          patchState({ webhooksByChatId });
        },
        (err) => {
          this.toastrService.error(
            err.message,
            this.translocoService.translate('toastr.title-error'),
          );
          throw err.error;
        },
      ),
    );
  }

  /**
   * Clear createdWebhook data
   * @param  {patchState}: StateContext<WebhookStateModel>
   * @param  {ClearCreatedWebhook} action
   */
  @Action(ClearCreatedWebhook)
  clearCreatedWebhook(
    { getState, patchState }: StateContext<WebhookStateModel>,
    action: ClearCreatedWebhook,
  ) {
    patchState({ createdWebhook: {} });
  }
}
