import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IntegrationsComponent } from '../../shared/components/integrations/integrations.component';
import { SvgComponent } from '../../shared/svgs/svg/svg.component';
import { TranslocoDirective } from '@ngneat/transloco';

@Component({
  selector: 'app-chat-integrations-modal',
  templateUrl: './chat-integrations.component.html',
  styleUrls: ['./chat-integrations.component.scss'],
  standalone: true,
  imports: [TranslocoDirective, SvgComponent, IntegrationsComponent],
})
export class ChatIntegrationsModalComponent {
  public chatId: string;
  public spaceId: string;
  public chatName: string;
  public platform: string;

  constructor(private activeModal: NgbActiveModal) {}

  close() {
    this.activeModal.close();
  }
}
