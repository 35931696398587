export enum WebhookIntegrationServiceType {
  GITHUB = 'github',
  GITLAB = 'gitlab',
  BITBUCKET = 'bitbucket',
}

export interface WebhookMessageData {
  serviceType: string;
  eventName: string;
  data:
    | GitHubPullRequestMessageData
    | GitHubPushMessageData
    | GitHubJobStatusData
    | GitLabPushMessageData
    | GitLabMergeRequestMessageData
    | GitLabPipeMsgData
    | BitbucketPushMessageData
    | BitbucketPRMessageData
    | BitbucketJobStatusData;
}

export interface GitHubPullRequestMessageData {
  action: string;
  number: number;
  pull_request: PullRequestDataWebhook;
  repository: {
    full_name: string;
    html_url: string;
  };
}

export interface GitHubPushMessageData {
  ref: string;
  before: string;
  after: string;
  repository: {
    full_name: string;
    html_url: string;
  };
  pusher: {
    name: string;
  };
  commits: [];
  head_commit: {
    id: string;
    message: string;
    url: string;
  };
}

export interface PullRequestDataWebhook {
  id: number;
  url: string;
  html_url: string;
  number: number;
  state: string;
  locked: boolean;
  title: string;
  user: {
    login: string;
    html_url: string;
  };
  body: string;
  assignees: {
    login: string;
  }[];
  requested_reviewers: {
    login: string;
  }[];
  head: {
    ref: string;
  };
  base: {
    ref: string;
  };
  merged: boolean;
}

export interface GitLabPushMessageData {
  object_kind: string;
  event_name: string;
  ref: string;
  message: string;
  user_id: string;
  user_name: string;
  user_username: string;
  project: {
    id: string;
    name: string;
    description: string;
    web_url: string;
    path_with_namespace: string;
  };
  commits: GitLabCommit[];
  repository: {
    name: string;
    url: string;
    description: string;
    homepage: string;
  };
}

export interface GitLabCommit {
  id: string;
  message: string;
  title: string;
  url: string;
  author: {
    name: string;
    email: string;
  };
}

export interface GitLabMergeRequestMessageData {
  object_kind: string;
  event_type: string;
  user: {
    id: number;
    username: string;
    avatar_url: string;
    email: string;
  };
  object_attributes: {
    assignee_id: number;
    author_id: number;
    created_at: string;
    description: string;
    head_pipeline_id: number;
    id: number;
    iid: number;
    source_branch: string;
    target_branch: string;
    title: string;
    url: string;
    action: string;
    state: string;
  };
  assignees: {
    name: string;
    username: string;
    avatar_url: string;
  }[];
  reviewers: {
    name: string;
    username: string;
    avatar_url: string;
  }[];
  project: {
    name: string;
  };
}

export interface GitLabPipeMsgData {
  object_kind: string;
  commit: {
    id: string;
    message: string;
    url: string;
  };
  object_attributes: {
    status: string;
    stages: string[];
    iid: number;
  };
  project: {
    path_with_namespace: string;
    git_http_url: string;
  };
}

export interface BitbucketPushMessageData {
  actor: {
    display_name: string;
    nickname: string;
  };
  push: {
    changes: {
      commits: BitbucketPushMessageCommit[];
      old: {
        name: string;
        target: {
          message: string;
        };
      };
      new: {
        name: string;
        target: {
          message: string;
        };
      };
    }[];
  };
  repository: {
    full_name: string;
  };
}

export interface BitbucketPushMessageCommit {
  hash: string;
  links: {
    html: {
      href: string;
    };
  };
}

export interface BitbucketPRMessageData {
  actor: {
    display_name: string;
    nickname: string;
    links: {
      html: {
        href: string;
      };
    };
  };
  repository: {
    full_name: string;
  };
  pullrequest: {
    id: number;
    title: string;
    description: string;
    reviewers: {
      display_name: string;
      links: {
        html: {
          href: string;
        };
      };
    }[];
    links: {
      html: {
        href: string;
      };
    };
    source: {
      branch: {
        name: string;
      };
    };
    destination: {
      branch: {
        name: string;
      };
    };
  };
}

export interface BitbucketJobStatusData {
  actor: {
    display_name: string;
  };
  repository: {
    full_name: string;
  };
  commit_status: {
    name: string;
    description: string;
    state: string;
    key: string;
    type: string;
    url: string;
  };
}

export interface GitHubJobStatusData {
  action: string;
  repository: {
    full_name: string;
    html_url: string;
  };
  sender: {
    html_url: string;
    login: string;
  };
  workflow_job: {
    html_url: string;
    status: string;
    name: string;
    runner_group_name: string;
    run_id: number;
    run_url: string;
    conclusion: string;
  };
}
