import { Component, OnDestroy, OnInit, forwardRef } from '@angular/core';
import { Actions, Store } from '@ngxs/store';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { WrappedSocket } from 'ngx-socket-io/src/socket-io.service';
import { TranslocoService, TranslocoDirective } from '@ngneat/transloco';

import { SpacesDbDto } from '../../api/models/spaces-db-dto';
import { SocketsService } from '../../shared/services/sockets.service';
import { SpaceGetUsersListForCreateChatGroup } from '../../shared/store/actions/spaces.action';
import { AuthState } from '../../shared/store/states/auth.state';
import { ConfirmAlert } from '../../shared/alerts/alerts';
import { UsersDbDto } from '../../api/models/users-db-dto';
import { AvatarComponent } from '../../standalone/components/avatar/avatar.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgIf } from '@angular/common';
import { FocusDirective } from '../../shared/directives/focus.directive';
import { FormsModule } from '@angular/forms';
import { SvgComponent } from '../../shared/svgs/svg/svg.component';

@Component({
  selector: 'app-chat-group-create-modal',
  templateUrl: './chat-group-create.component.html',
  styleUrls: ['./chat-group-create.component.scss'],
  standalone: true,
  imports: [
    TranslocoDirective,
    SvgComponent,
    FormsModule,
    FocusDirective,
    NgIf,
    NgSelectModule,
    forwardRef(() => AvatarComponent),
  ],
})
export class ChatGroupCreateModalComponent implements OnInit, OnDestroy {
  platform = 'web';
  socket: WrappedSocket;
  destroy$: Subject<void> = new Subject<void>();
  usersSubs$: Subscription;
  space: SpacesDbDto;
  users: any[];
  currentUser: UsersDbDto;
  isSubmitting = false;

  constructor(
    private actions: Actions,
    private activeModal: NgbActiveModal,
    private store: Store,
    private toastr: ToastrService,
    private socketsService: SocketsService,
    private translocoService: TranslocoService,
  ) {
    this.socket = this.socketsService.get();
  }

  /**
   * Subscribe to display modal action
   */
  ngOnInit() {
    this.getPlatform();
    this.getUserList();
    this.socket.on('chats:createGroup:response', () => {
      this.toastr.success(
        this.translocoService.translate('toastr.message-group-chat-created'),
        this.translocoService.translate('toastr.title-success'),
      );
      this.activeModal.close();
    });
    this.currentUser = this.store.selectSnapshot(AuthState.getUser);
  }

  /**
   * Close modal handler
   */
  close(form) {
    if (form.dirty) {
      ConfirmAlert(null, {
        subject: this.translocoService.translate('alert.close-modal-subject'),
        text: this.translocoService.translate('alert.close-modal-text'),
        showCancelButton: true,
        cancelButtonText: this.translocoService.translate('alert.close-modal-btn-close'),
        showDenyButton: true,
        denyButtonText: this.translocoService.translate('alert.close-modal-btn-discard'),
        denyButtonClass: 'btn-subtle',
        confirmButtonText: this.translocoService.translate('alert.close-modal-btn-save'),
        confirmButtonClass: 'btn-solid',
        platform: this.platform,
      }).then(
        (result) => {
          if (result === 'isConfirmed') {
            this.addChatGroup(form);
          }
          if (result === 'isDenied') {
            this.activeModal.close();
          }
        },
        () => {
          this.activeModal.close();
        },
      );
    } else {
      this.activeModal.close();
    }
  }

  addChatGroup(form) {
    if (this.isSubmitting) {
      return;
    }

    this.isSubmitting = true;

    this.socket.emit(
      'chats:createGroup',
      {
        name: form.value.chatGroupName,
        members: form.value.chatGroupMembers,
        spaceId: this.space._id,
      },
      () => {
        this.isSubmitting = false;
      },
    );
  }

  ngOnDestroy() {
    this.socket.removeListener('chats:createGroup:response');
    this.usersSubs$?.unsubscribe();
    this.destroy$.next();
    this.destroy$.complete();
  }

  getPlatform(): void {
    this.platform = this.store.selectSnapshot(AuthState.getPlatform);
  }

  getUserList(): void {
    this.usersSubs$ = this.store
      .dispatch(new SpaceGetUsersListForCreateChatGroup({ id: this.space._id }))
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (res) {
          this.users = res.Spaces.usersForGroup.filter(
            (spaceUser) => spaceUser._id !== this.currentUser?._id,
          );
        }
      });
  }
}
