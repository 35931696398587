// db.ts
import Dexie, { Table } from 'dexie';

export class AppDatabase extends Dexie {
  chats!: Table<any, number>;
  projects!: Table<any, number>;
  spaces!: Table<any, number>;
  uploadMessage!: Table<any, number>;

  constructor() {
    super('teamplate');
    this.version(3).stores({
      uploadMessage: '++timestamp',
    });
  }

  addUploadMessage(message: any) {
    return this.uploadMessage.add(message);
  }

  getUploadMessages() {
    return this.uploadMessage.toArray();
  }

  deleteUploadMessage(id: number) {
    return this.uploadMessage.delete(id);
  }
}

export const db = new AppDatabase();
