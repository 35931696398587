import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { NgIf } from '@angular/common';
import { ImageCropperComponent } from '../image-cropper/image-cropper.component';
import { SvgComponent } from '../../svgs/svg/svg.component';
import { TranslocoDirective } from '@ngneat/transloco';
import { MixpanelService } from '../../../plugins/mixpanel/mixpanel.service';

@Component({
  selector: 'app-avatar-image-show-edit',
  templateUrl: './avatar-image-show-edit.component.html',
  styleUrls: ['./avatar-image-show-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TranslocoDirective, SvgComponent, ImageCropperComponent, NgIf],
})
export class AvatarImageShowEditComponent {
  @ViewChild('imageCropperModal') imageCropperModal: ElementRef;

  @ViewChild('avatarImageInput') avatarImageInput: ElementRef;

  @Input() avatarImagePreview: string;

  @Output() deleteImage = new EventEmitter<void>();

  @Output() setImageFile = new EventEmitter<File>();

  @Output() setImagePreview = new EventEmitter<string>();

  public imageChangedEvent: Event;

  private cropperModal: NgbModalRef;

  public avatarImage: File;

  constructor(private modalsService: NgbModal) {}

  public avatarChangeEvent(event: Event): void {
    MixpanelService.trackEvent('Avatar: image changed');
    this.imageChangedEvent = event;

    if ((this.imageChangedEvent?.target as any)?.files[0]?.name) {
      this.cropperModal = this.modalsService.open(this.imageCropperModal, {
        backdrop: 'static',
        keyboard: false,
        windowClass: 'cropper-modal',
      });
    }
  }

  public closeCropperModal(): void {
    MixpanelService.trackEvent('Avatar: image cropper closed');
    this.cropperModal.close();
    this.avatarImageInput.nativeElement.value = '';
  }

  public setFile(file: File): void {
    MixpanelService.trackEvent('Avatar: image cropped');
    this.avatarImage = file;
    this.setImageFile.emit(file);
  }

  public setImagePrev(imageDataBase64: string): void {
    this.setImagePreview.emit(imageDataBase64);
  }

  public deleteAvatarImage(): void {
    this.deleteImage.emit();
    this.avatarImagePreview = undefined;
    this.imageChangedEvent = undefined;
    this.avatarImageInput.nativeElement.value = '';
  }
}
