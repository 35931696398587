<div
  class="modal-body space-delete"
  [class.dark-delete]="config?.layout.variant === 'Dark'"
  *transloco="let t; read: 'modals.space'"
>
  <form [formGroup]="spaceDeleteForm">
    <div class="space-delete__title">
      {{ space ? t('title-confirm-space-deletion') : t('title-confirm-project-deletion') }}
      <span>{{ space ? space.spaceName : project.projectName }}</span>
      {{ t('title-confirm-deletion') }}
    </div>
    <div class="d-flex align-items-center space-delete__accepted">
      <input
        id="space-delete"
        class="mr-2"
        type="checkbox"
        formControlName="acceptedDeleteSpace"
        required
        t-id="space-delete-checkbox"
      />
      <span>
        {{ space ? t('agreement-space') : t('agreement-project') }}
      </span>
    </div>
    <div class="space-delete__password">
      <label for="passwordSpace">
        {{ space ? t('form-space-password') : t('form-project-password') }}
      </label>
      <input
        t-id="password-space"
        id="passwordSpace"
        [placeholder]="t('enter-password-placeholder')"
        formControlName="passwordUser"
        tabindex="1"
        type="password"
        required
        class="form-control"
        t-id="space-delete-input-password"
      />
    </div>
    <div class="d-flex flex-column align-items-center justify-content-between space-delete__button">
      <button
        class="btn btn-solid btn-save w-100"
        type="button"
        (click)="onSubmit()"
        [disabled]="spaceDeleteForm.invalid"
        t-id="space-delete-submit-button"
      >
        {{ space ? t('btn-delete-space') : t('btn-delete-project') }}
      </button>
      <button
        class="btn btn-subtle w-100"
        type="button"
        (click)="close()"
        t-id="space-delete-cancel-button"
      >
        {{ t('btn-cancel') }}
      </button>
    </div>
  </form>
</div>
