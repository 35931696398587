import { Component, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { WebhookCreateUpdateModalComponent } from '../../../modals/webhook-create-update/webhook-create-update-modal.component';
import { WebhooksDbDto } from '../../../api/models/webhooks-db-dto';
import { ConfigService } from '../../services/config.service';
import { CheckPermissionPipe } from '../../pipes/check-permission.pipe';
import { WebhooksListComponent } from '../webhooks-list/webhooks-list.component';
import { NgIf, NgClass } from '@angular/common';
import { TranslocoDirective } from '@ngneat/transloco';
import { MixpanelService } from '../../../plugins/mixpanel/mixpanel.service';

type TabNames = 'incoming-webhooks';

@Component({
  selector: 'app-integrations',
  templateUrl: './integrations.component.html',
  styleUrls: ['./integrations.component.scss'],
  standalone: true,
  imports: [TranslocoDirective, NgIf, NgClass, WebhooksListComponent, CheckPermissionPipe],
})
export class IntegrationsComponent {
  @Input() spaceId: string;
  @Input() chatId: string;
  @Input() platform = 'web';
  @Input() activeTab: TabNames = 'incoming-webhooks';

  public darkMode = false;

  constructor(
    private modalService: NgbModal,
    private configService: ConfigService,
  ) {
    this.darkMode = this.configService.templateConf.layout.variant !== 'Light';
  }

  public createUpdateWebhookModal(wh?: WebhooksDbDto): void {
    MixpanelService.trackEvent('Webhooks: Create/update webhook');
    const modalRef = this.modalService.open(WebhookCreateUpdateModalComponent, {
      size: 'md',
      windowClass: 'create-update-webhooks-modal',
    });
    modalRef.componentInstance.chatId = this.chatId;
    modalRef.componentInstance.webhook = wh;
  }
}
