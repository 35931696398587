import {
  EventEmitter,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  Output,
  ViewChild,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormsModule,
  ReactiveFormsModule,
  NgForm,
} from '@angular/forms';
import { Actions, Store } from '@ngxs/store';
import { Subject, Subscription } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { WrappedSocket } from 'ngx-socket-io/src/socket-io.service';
import { TranslocoService, TranslocoDirective } from '@ngneat/transloco';
import { CommonModule } from '@angular/common';
import { ChatsDbDto } from '../../../api/models/chats-db-dto';
import { SocketsService } from '../../../shared/services/sockets.service';
import { SpaceGetUsersListForCreateChatGroup } from '../../../shared/store/actions/spaces.action';
import { ChatsState } from '../../../shared/store/states/chats.state';
import { ConfirmAlert } from '../../../shared/alerts/alerts';
import { UsersDbDto } from '../../../api/models/users-db-dto';
import { AuthState } from '../../../shared/store/states/auth.state';
import { TenantsState } from '../../../shared/store/states/tenants.state';
import { SpacesState } from '../../../shared/store/states/spaces.state';
import { NgIf } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';
import { SvgComponent } from '../../../shared/svgs/svg/svg.component';
import { NotificationsState } from '../../../shared/store/states/notifications.state';
import {
  NgbModal,
  NgbModalRef,
  NgbDropdown,
  NgbDropdownToggle,
  NgbDropdownMenu,
  NgbDropdownButtonItem,
  NgbDropdownItem,
} from '@ng-bootstrap/ng-bootstrap';
import {
  PushNotificationsSettingsCreate,
  PushNotificationsSettingsGet,
} from '../../../../app/shared/store/actions/notifications.action';
import { ChatsGet } from '../../../shared/store/actions/chats.action';
import { ToastrService } from 'ngx-toastr';
import { MixpanelService } from '../../../plugins/mixpanel/mixpanel.service';

@Component({
  selector: 'app-chat-group-settings-modal',
  templateUrl: './chat-group-settings.component.html',
  styleUrls: ['./chat-group-settings.component.scss'],
  standalone: true,
  imports: [
    TranslocoDirective,
    SvgComponent,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    NgIf,
    NgbDropdown,
    NgbDropdownToggle,
    NgbDropdownMenu,
    NgbDropdownButtonItem,
    NgbDropdownItem,
    CommonModule,
  ],
})
export class ChatGroupSettingsModalComponent implements OnInit, OnDestroy {
  @Input() platform = 'web';
  @Input() object: string = null;
  @Input() objectId: string = null;
  @Input() chatId: string = null;
  @Input() spaceId: string = null;
  @Input() isModalWindow = false;
  @Output() close = new EventEmitter<string>();

  [x: string]: any;
  socket: WrappedSocket;
  destroy$: Subject<void> = new Subject<void>();
  usersSubs$: Subscription;
  chatMembers: string[];
  users: any[];
  chat: ChatsDbDto;
  currentUser: UsersDbDto;
  initialChatMembers: string[];
  isAllowToRemoveMember: boolean;
  selectedFilter: string;
  notifyFilter: string;
  noRingtone: boolean;

  notifyFilters = [
    {
      value: 'ALL ACTIONS',
      label: this.translocoService.translate('project-settings.all-notifications'),
    },
    {
      value: 'MENTIONS',
      label: this.translocoService.translate('project-settings.only-mentions'),
    },
    {
      value: 'NO CALLS',
      label: this.translocoService.translate('project-settings.no-calls'),
    },
    {
      value: 'NOTHING',
      label: this.translocoService.translate('project-settings.nothing'),
    },
  ];

  constructor(
    private actions: Actions,
    private activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private store: Store,
    private socketsService: SocketsService,
    public cdr: ChangeDetectorRef,
    private translocoService: TranslocoService,
    private toastrService: ToastrService,
  ) {
    this.socket = this.socketsService.get();
  }

  ngOnInit() {
    this.store.dispatch(
      new PushNotificationsSettingsGet({
        object: 'group',
        objectId: this.chatId,
      }),
    );

    this.store
      .select(NotificationsState.getFilter)
      .pipe(
        takeUntil(this.destroy$),
        map((filterFn) => filterFn(this.chatId)),
      )
      .subscribe((filters: any) => {
        this.notifyFilter = filters?.filter || 'ALL ACTIONS';
        this.selectedFilter = this.notifyFilters.find(
          (item) => item.value === this.notifyFilter,
        ).label;
        this.noRingtone = filters?.noRingtone || false;
      });
  }

  ngOnDestroy() {
    this.usersSubs$?.unsubscribe();
    this.destroy$.next();
    this.destroy$.complete();
  }

  closeModal() {
    MixpanelService.trackEvent('Chat group settings: Close modal');
    this.activeModal.close();
  }

  selectFilter(item) {
    MixpanelService.trackEvent('Chat group settings: Select filter', {
      filter: item.label,
      value: item.value,
    });
    this.notifyFilter = item.value;
    this.selectedFilter = item.label;
  }

  saveSettings() {
    MixpanelService.trackEvent('Chat group settings: Save settings', {
      filter: this.notifyFilter,
      noRingtone: this.noRingtone,
    });
    const body = {
      object: 'group',
      objectId: this.chatId,
      filter: this.notifyFilter,
      noRingtone: this.noRingtone,
    };

    this.store
      .dispatch(new PushNotificationsSettingsCreate(body))
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.store.dispatch(new ChatsGet());
        this.closeModal();

        this.toastrService.success(
          this.translocoService.translate('toastr.notifications-settings-updated'),
          this.translocoService.translate('toastr.title-settings'),
        );
      });
  }

  /**
   * @param  {NgForm} form
   */
  updateSubmit(form: NgForm) {
    this.saveSettings();
    return;
  }
}
