import { Injectable } from '@angular/core';
import { Message } from '../components/chat/chat.model';
import { ChatsState } from '../store/states/chats.state';
import { LocalStorageService } from 'ngx-localstorage';
import { Store } from '@ngxs/store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Subject } from 'rxjs';
import { ThreadMessageCreate } from '../store/actions/threads.action';
import {
  ChatsGetMessages,
  ChatsMessageCreate,
  ThreadGetMessages,
} from '../store/actions/chats.action';
import { HttpClient } from '@angular/common/http';
import { AppDatabase } from '../../standalone/helpers/app.database';

interface IOfflineProps {
  chatId?: string;
  threadId?: string;
  isThread?: boolean;
}

@UntilDestroy({ checkProperties: true })
@Injectable({
  providedIn: 'root',
})
export class OfflineMessagesService {
  public messagesChat: Message[];
  public messagesThread: Message[];
  public messagePending: Message[] = [];
  public isComplete = new Subject<void>();

  constructor(
    private localStorage: LocalStorageService,
    private http: HttpClient,
    private store: Store,
    private adb: AppDatabase,
  ) {}

  public setChatMessages(messages): void {
    this.messagesChat = [...messages];
  }

  public getChatMessages(): Message[] {
    return this.messagesChat;
  }

  public setThreadMessages(messages): void {
    this.messagesThread = [...messages];
  }

  public getThreadMessages(): Message[] {
    return this.messagesThread;
  }

  public async resendMessages(payload: IOfflineProps): Promise<void> {
    const local: Message[] = await this.adb.getUploadMessages();
    const isOffline = this.store.selectSnapshot(ChatsState.getStatus);

    if (local && !isOffline) {
      if (!payload.isThread && payload.chatId) {
        this.chatResend(payload.chatId, local);
      } else if (payload.threadId) {
        this.threadResend(payload.threadId, local);
      }
    }
  }

  private chatResend(chatId: string, local: Message[]): void {
    const offlineMessages = local.filter((message) => message.chatId === chatId);

    if (offlineMessages.length) {
      const request = {
        chatId: chatId,
        page: 1,
        perPage: 20,
        skipMarkAsReed: true,
        isLocalState: true,
      };

      this.store
        .dispatch(new ChatsGetMessages(request))
        .pipe(untilDestroyed(this))
        .subscribe(() => {
          const messages = this.getChatMessages();
          const messagesToSend = offlineMessages
            .sort((a, b) => a.timestamp - b.timestamp)
            .filter((message) => !this.isMessageEqual(messages, message));

          if (messagesToSend.length) {
            this.sendMessages(messagesToSend, false).then(() => this.isComplete.next());
          }
        });
    }
  }

  private threadResend(threadId: string, local: Message[]): void {
    const offlineMessages = local.filter((message) => message.threadId === threadId);
    if (offlineMessages.length) {
      const request = {
        threadId: threadId,
        isLocalState: true,
      };
      this.store
        .dispatch(new ThreadGetMessages(request))
        .pipe(untilDestroyed(this))
        .subscribe(() => {
          const messages = this.getThreadMessages();
          const messagesToSend = offlineMessages
            .sort((a, b) => a.timestamp - b.timestamp)
            .filter((message) => !this.isMessageEqual(messages, message));

          if (messagesToSend.length) {
            this.sendMessages(messagesToSend, true).then(() => this.isComplete.next());
          }
        });
    }
  }

  private async sendMessages(messages: Array<Message>, isThread: boolean) {
    for (let i = 0; i < messages.length; i++) {
      const message = messages[i];
      if (this.messagePending.some((msg) => msg.timestamp === message.timestamp)) {
        return;
      }

      this.messagePending = [...this.messagePending, message];

      await this.store
        .dispatch(this.resendMessageAction(message, isThread))
        .toPromise()
        .then(() => {
          this.messagePending = this.messagePendingFilter(message);
        })
        .catch(() => {
          this.messagePending = this.messagePendingFilter(message);
        });
    }
  }

  private messagePendingFilter(message: Message): Message[] {
    return this.messagePending.filter((msg) => msg.timestamp !== message.timestamp);
  }

  private isMessageEqual(messages: Message[], message: Message): boolean {
    return messages.some(
      (msg) => msg.timestamp === message.timestamp && msg.userId === message.userId,
    );
  }

  private resendMessageAction(message: Message, isThread: boolean) {
    return isThread ? new ThreadMessageCreate(message) : new ChatsMessageCreate(message);
  }
}
