<ng-container *transloco="let t; read: 'modals.chat-group-settings'">
  <div class="modal-header">
    <h5 class="modal-title" [innerHTML]="t('title', { chatName: chat?.chatName })"></h5>
    <button type="button" class="close" aria-label="Close" (click)="closeModal()">
      <app-svg name="times" width="10" height="10" class="svg-icon" aria-hidden="true"></app-svg>
    </button>
  </div>
  <div class="modal-body">
    <section id="ngx">
      <form #updateForm="ngForm" class="w-100" novalidate>
        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <h6 class="card-title" [class.mt-3]="!projectDesc">
                {{ t('notifications-title') }}
              </h6>
              <div class="settings-wrapper">
                <label for="selectFilter">{{ t('notify-me-about') }}</label>
                <div ngbDropdown placement="bottom-right">
                  <button type="button" class="btn btn-text" id="selectFilter" ngbDropdownToggle>
                    {{ selectedFilter }}
                    <i class="ft-chevron-down font-small-4" aria-hidden="true"></i>
                  </button>
                  <div ngbDropdownMenu aria-labelledby="selectFilter">
                    <button
                      *ngFor="let item of notifyFilters"
                      class="w-100"
                      ngbDropdownItem
                      (click)="selectFilter(item)"
                    >
                      {{ item.label }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group text-right">
              <button class="btn btn-subtle btn-cancel mr-2" (click)="closeModal()">
                {{ t('btn-cancel') }}
              </button>
              <button
                t-id="chat-group-create-button"
                type="button"
                uiSref="work"
                class="btn btn-solid"
                (click)="updateSubmit(updateForm)"
              >
                {{ t('btn-save') }}
              </button>
            </div>
          </div>
        </div>
      </form>
    </section>
  </div>
</ng-container>
