import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslocoService, TranslocoDirective } from '@ngneat/transloco';
import { Store } from '@ngxs/store';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { AuthState } from '../../shared/store/states/auth.state';
import { ChatsState } from '../../shared/store/states/chats.state';
import { ProjectSettingsComponent } from '../../shared/components/project-settings/project-settings.component';
import { ConfirmAlert } from '../../shared/alerts/alerts';
import { SvgComponent } from '../../shared/svgs/svg/svg.component';

@Component({
  selector: 'app-space-update-modal',
  templateUrl: './space-update.component.html',
  styleUrls: ['./space-update.component.scss'],
  standalone: true,
  imports: [TranslocoDirective, SvgComponent, ProjectSettingsComponent],
})
export class SpaceUpdateModalComponent implements OnInit {
  platform: string;
  objectId: string;
  chatId: string;
  @ViewChild('projectSettingsComponent')
  projectSettings: ProjectSettingsComponent;

  constructor(
    private activeModal: NgbActiveModal,
    private store: Store,
    private translocoService: TranslocoService,
  ) {}

  /**
   * Subscribe to display modal action
   */
  ngOnInit() {
    this.getPlatform();
    this.chatId = this.store
      .selectSnapshot(ChatsState.getChats)
      .find((item) => item.objectId === this.objectId)._id;
  }

  /**
   * Close modal handler
   */
  close(reason?: string) {
    if (this.projectSettings.updateForm.dirty && reason !== 'saved') {
      ConfirmAlert(null, {
        subject: this.translocoService.translate('alert.close-modal-subject'),
        text: this.translocoService.translate('alert.close-modal-text'),
        cancelButtonText: this.translocoService.translate('alert.close-modal-btn-close'),
        showDenyButton: true,
        denyButtonText: this.translocoService.translate('alert.close-modal-btn-discard'),
        denyButtonClass: 'btn-subtle',
        confirmButtonText: this.translocoService.translate('alert.close-modal-btn-save'),
        confirmButtonClass: 'btn-solid',
        platform: this.platform,
      }).then(
        (result) => {
          if (result === 'isDenied') {
            this.activeModal.close();
          }
          if (result === 'isConfirmed') {
            this.submitForm();
          }
        },
        () => {},
      );
    } else {
      this.activeModal.close();
    }
  }

  getPlatform(): void {
    this.platform = this.store.selectSnapshot(AuthState.getPlatform);
  }

  submitForm() {    
    if (
      this.projectSettings &&
      this.projectSettings.updateForm &&
      this.projectSettings.updateForm.valid
    ) {
      this.projectSettings.updateSubmit(this.projectSettings.updateForm);
    }
  }
}
