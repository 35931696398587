import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { TranslocoService } from '@ngneat/transloco';

import { RouterTenantPipe } from '../pipes/router-tenant.pipe';
import { ConfirmAlert } from '../alerts/alerts';
import { SocketsService } from './sockets.service';
import {
  ProjectDelete,
  ProjectLeave,
  ProjectToggleArchiveStatus,
} from '../store/actions/projects.action';
import { ProjectCreateModalComponent } from '../../modals/project-create/project-create.component';
import { ProjectUpdateModalComponent } from '../../modals/project-update/project-update.component';
import { AuthState } from '../store/states/auth.state';
import { ChatsGet } from '../store/actions/chats.action';

@Injectable({
  providedIn: 'root',
})
export class ProjectService {
  constructor(
    private store: Store,
    private router: Router,
    private routerTenantPipe: RouterTenantPipe,
    private modalService: NgbModal,
    protected socketsService: SocketsService,
    protected toastrService: ToastrService,
    private translocoService: TranslocoService,
  ) {}

  /**
   * Create project button handler (emit show modal)
   */
  createClick(space) {
    const modalRef = this.modalService.open(ProjectCreateModalComponent, {
      windowClass: `creation-modal ${this.store.selectSnapshot(AuthState.getPlatform) !== 'web' ? 'mobile' : ''}`,
    });
    modalRef.componentInstance.spaceId = space._id;
  }

  /**
   * Update project button handler (emit show modal)
   */
  updateClick(objectId) {
    const modalRef = this.modalService.open(ProjectUpdateModalComponent, {
      size: 'lg',
    });
    modalRef.componentInstance.objectId = objectId;
  }

  /**
   * Leave project button handler (emit show modal)
   */

  leaveClick(project) {
    this.store.dispatch(new ProjectLeave({ id: project._id })).subscribe(
      () => {
        this.toastrService.success(
          this.translocoService.translate('toastr.you-leaved-from-project'),
          this.translocoService.translate('toastr.project-leave'),
        );

        this.store.dispatch(new ChatsGet());
      },
      (err) => {
        this.toastrService.error(
          err.message,
          this.translocoService.translate('toastr.title-error'),
        );
      },
    );
  }

  /**
   * Delete project button handler (emit confirm alert)
   */
  deleteClick(id) {
    ConfirmAlert(this.translocoService.translate('alert.project-title'), {
      platform: this.store.selectSnapshot(AuthState.getPlatform),
    }).then(
      () => {
        this.store.dispatch(new ProjectDelete({ id })).subscribe(
          () => {
            this.toastrService.success(
              this.translocoService.translate('toastr.project-successfully-deleted'),
              this.translocoService.translate('toastr.project-deleted-title'),
            );

            this.store.dispatch(new ChatsGet());

            this.router.navigate([this.routerTenantPipe.transform('dash')]);
          },
          (err) => {
            this.toastrService.error(
              err.message,
              this.translocoService.translate('toastr.title-error'),
            );
          },
        );
      },
      () => {},
    );
  }

  deleteProject(id) {
    this.store.dispatch(new ProjectDelete({ id })).subscribe(
      () => {
        this.toastrService.success(
          this.translocoService.translate('toastr.project-successfully-deleted'),
          this.translocoService.translate('toastr.project-deleted-title'),
        );

        this.store.dispatch(new ChatsGet());
      },
      (err) => {
        this.toastrService.error(
          err.message,
          this.translocoService.translate('toastr.title-error'),
        );
      },
    );
  }

  unArchive(project) {
    ConfirmAlert(this.translocoService.translate('alert.project-title'), {
      platform: this.store.selectSnapshot(AuthState.getPlatform),
      confirmButtonClass: 'btn-solid',
      confirmButtonText: this.translocoService.translate('alert.btn-unarchive'),
      subject: this.translocoService.translate('alert.unarchive-project-subject'),
      text: '',
    }).then(
      () => {
        this.store.dispatch(new ProjectToggleArchiveStatus({ id: project._id })).subscribe(
          () => {
            this.toastrService.success(
              this.translocoService.translate('toastr.project-has-been-unarchived', {
                projectName: project.projectName,
              }),
              this.translocoService.translate('toastr.title-success'),
              {
                enableHtml: true,
              },
            );

            this.store.dispatch(new ChatsGet());
          },
          (err) => {
            this.toastrService.error(
              err.message,
              this.translocoService.translate('toastr.title-error'),
            );
          },
        );
      },
      () => {},
    );
  }
}
