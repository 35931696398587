<ng-container *transloco="let t; read: 'modals.webhook-create-update'">
  <div class="modal-header">
    <button type="button" class="close" aria-label="Close" (click)="close()">
      <app-svg name="times" width="10" height="10" class="svg-icon" aria-hidden="true"></app-svg>
    </button>
  </div>

  <div class="modal-body">
    <h3 class="main-title mb-3">
      {{ isNewWebhook ? t('add-webhook') : t('edit-webhook') }}
    </h3>

    <div class="row">
      <div class="col-12">
        <form [formGroup]="webhookItemForm">
          <div class="form-group mb-3">
            <div>
              <label for="webhook-name">{{ t('form-webhook-name') }}</label>
              <div class="d-flex flex-column position-relative">
                <input
                  id="webhook-name"
                  formControlName="webhookName"
                  name="webhookName"
                  class="form-control"
                  [placeholder]="t('webhook-name-placeholder')"
                  aria-invalid="false"
                  t-id="webhook-create-update-modal-webhook-name"
                />
                <small
                  class="form-text danger field-error"
                  *ngIf="
                    !webhookItemForm.controls['webhookName'].valid &&
                    (webhookItemForm.controls['webhookName'].dirty ||
                      webhookItemForm.controls['webhookName'].touched)
                  "
                >
                  {{ t('form-required') }}
                </small>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="form-group">
          <div *ngIf="isNewWebhook" class="controls mb-3">
            <label for="whType">{{ t('form-webhook-type') }}</label>
            <ng-select
              [items]="webhookUrlTypes"
              [(ngModel)]="whUrlTypeValue"
              [clearable]="false"
              id="whType"
              bindLabel="label"
              bindValue="value"
              [placeholder]="t('webhook-type-placeholder')"
              t-id="webhook-create-update-type"
            ></ng-select>
          </div>
          <div *ngIf="!isNewWebhook">
            <label
              >URL <span> for {{ getUrlTypeLabel(whUrlTypeValue) }}</span></label
            >
            <div class="form-control pr-3 position-relative">
              <div class="hook-url" t-id="webhook-create-update-modal-url">
                {{ whUrlWithType(whUrlTypeValue) }}
              </div>
              <i
                class="ft-copy copy-wh-url"
                triggers="hover"
                [ngbTooltip]="t('copy-email-tooltip')"
                container="body"
                placement="top"
                *ngIf="!isNewWebhook"
                (click)="copyToClipboard()"
              ></i>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row select-ava-row">
      <div class="col-12 d-flex">
        <div class="d-flex w-100 justify-content-between">
          <div class="col-6">
            <app-avatar-image-show-edit
              [avatarImagePreview]="avatarImagePreview"
              (setImageFile)="setWebhookImageFile($event)"
              (setImagePreview)="setImagePreview($event)"
              (deleteImage)="deleteAvatarImage()"
            ></app-avatar-image-show-edit>
          </div>

          <div class="col-6">
            <div class="d-flex flex-column justify-content-center align-items-center mt-2">
              <button
                class="btn btn-solid emoji-picker-icon"
                (click)="emojiPickerToggle()"
                t-id="webhook-create-update-modal-select-emoji"
              >
                {{ t('btn-select-emoji') }}
              </button>
              <div class="selected-emoji">{{ selectedEmoji }}</div>
              <button
                *ngIf="selectedEmoji && selectedEmoji !== ''"
                class="btn btn-subtle"
                (click)="clearEmoji()"
              >
                {{ t('btn-clear-emoji') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <div class="w-100 footer">
      <div class="d-flex flex-row justify-content-end w-100">
        <button type="reset" class="btn btn-subtle" (click)="close()">
          {{ t('btn-cancel') }}
        </button>
        <button
          type="submit"
          class="btn btn-solid ml-2"
          [disabled]="
            !(isAvatarDeleted || isAvatarChanged || isEmojiTouched || webhookItemForm.dirty) ||
            isProcessing
          "
          (click)="saveUpdateNewWebhook()"
          t-id="webhook-create-update-modal-save-add-webhook"
        >
          {{ isNewWebhook ? t('btn-add-webhook') : t('btn-save') }}
        </button>
      </div>
    </div>
  </div>
</ng-container>

<emoji-mart
  *ngIf="emojiIsOpen"
  class="webhook-emoji-picker"
  title=""
  emoji=""
  [backgroundImageFn]="emojiPickerImageFn"
  [autoFocus]="true"
  [isNative]="true"
  [darkMode]="darkMode"
  [exclude]="['flags']"
  [recent]="customRecentEmojis"
  (emojiSelect)="addEmoji($event)"
></emoji-mart>
