import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Store } from '@ngxs/store';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import moment from 'moment-timezone';
import { TranslocoService } from '@ngneat/transloco';

import { ChatsDbDto } from '../../../api/models/chats-db-dto';
import { WebhooksDbDto } from '../../../api/models/webhooks-db-dto';
import { ConfirmAlert } from '../../alerts/alerts';
import { WebhookDelete, WebhooksByChatIdGet } from '../../store/actions/webhook.actions';
import { WebhooksState } from '../../store/states/webhook.state';
import { AuthState } from '../../store/states/auth.state';
import { CheckPermissionPipe } from '../../pipes/check-permission.pipe';
import { SvgComponent } from '../../svgs/svg/svg.component';
import { NgbDropdown, NgbDropdownToggle, NgbDropdownMenu } from '@ng-bootstrap/ng-bootstrap';
import { WebhookAvatarComponent } from '../chat/chat-messages/webhook-avatar/webhook-avatar.component';
import { NgFor, NgClass, NgIf, DatePipe } from '@angular/common';
import { MixpanelService } from '../../../plugins/mixpanel/mixpanel.service';

@Component({
  selector: 'app-webhooks-list',
  templateUrl: './webhooks-list.component.html',
  styleUrls: ['./webhooks-list.component.scss'],
  standalone: true,
  imports: [
    NgFor,
    NgClass,
    WebhookAvatarComponent,
    NgIf,
    NgbDropdown,
    SvgComponent,
    NgbDropdownToggle,
    NgbDropdownMenu,
    DatePipe,
    CheckPermissionPipe,
  ],
})
export class WebhooksListComponent implements OnInit, OnDestroy {
  @Input() chatId: string;
  @Input() spaceId: string;
  @Input() darkMode = false;

  @Output() editeModalOpen = new EventEmitter<WebhooksDbDto>();

  private destroy$: Subject<void> = new Subject<void>();
  public chat: ChatsDbDto;
  public webhooks: WebhooksDbDto[] = [];

  constructor(
    private store: Store,
    private toastrService: ToastrService,
    private cdr: ChangeDetectorRef,
    private translocoService: TranslocoService,
  ) {}

  ngOnInit() {
    if (this.chatId) {
      this.store.dispatch(new WebhooksByChatIdGet(this.chatId));
    }

    this.store
      .select(WebhooksState.getWebhooksByChatIdMap)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (res && res[this.chatId]) {
          const wHooks = [...res[this.chatId]];
          this.webhooks = wHooks?.sort((a, b) =>
            moment.utc(a.created_at).diff(moment.utc(b.created_at)),
          );
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  openEditModal(wh: WebhooksDbDto): void {
    MixpanelService.trackEvent('Webhook: Edit Modal Opened');
    this.editeModalOpen.emit(wh);
  }

  public delete(webhook: WebhooksDbDto): void {
    MixpanelService.trackEvent('Webhook: Delete');
    ConfirmAlert(this.translocoService.translate('alert.webhook-title'), {
      platform: this.store.selectSnapshot(AuthState.getPlatform),
    }).then(
      () => {
        this.store
          .dispatch(new WebhookDelete({ id: webhook._id, chatId: this.chatId }))
          .pipe(take(1))
          .subscribe(() => this.cdr.detectChanges());
      },
      (err) =>
        err?.message &&
        this.toastrService.error(
          err.message,
          this.translocoService.translate('toastr.title-error'),
        ),
    );
  }
}
