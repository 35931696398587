import {
  EventEmitter,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  Output,
  ViewChild,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { NgForm, FormsModule } from '@angular/forms';
import { Actions, ofActionDispatched, Store } from '@ngxs/store';
import {
  NgbModal,
  NgbModalRef,
  NgbDropdown,
  NgbDropdownToggle,
  NgbDropdownMenu,
  NgbDropdownButtonItem,
  NgbDropdownItem,
} from '@ng-bootstrap/ng-bootstrap';
import { Subject, Subscription } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { TranslocoService, TranslocoDirective } from '@ngneat/transloco';

import {
  PushNotificationsSettingsCreate,
  PushNotificationsSettingsGet,
} from '../../store/actions/notifications.action';
import {
  ProjectsEmojiPicker,
  ProjectSetInfo,
  ProjectsSetEmoji,
  ProjectUpdate,
} from '../../store/actions/projects.action';
import { ChatsGet } from '../../store/actions/chats.action';
import { AvatarDelete, ProjectAvatarSet, SpaceAvatarSet } from '../../store/actions/avatar.action';
import { SpaceSetInfo, SpaceUpdate } from '../../store/actions/spaces.action';
import { SpacesState } from '../../store/states/spaces.state';
import { ProjectsState } from '../../store/states/projects.state';
import { NotificationsState } from '../../store/states/notifications.state';
import { CheckPermissionPipe } from '../../pipes/check-permission.pipe';
import { ImageCropperComponent } from '../image-cropper/image-cropper.component';
import { SvgComponent } from '../../svgs/svg/svg.component';
import { ProjectAvatarComponent } from '../space-projects/project-avatar/project-avatar.component';
import { CustomSpinnerComponent } from '../custom-spinner/custom-spinner.component';
import { SpaceAvatarComponent } from '../space-projects/space-avatar/space-avatar.component';
import { NgIf, NgFor, DatePipe } from '@angular/common';
import { NgScrollbar } from 'ngx-scrollbar';
import { ConfirmAlert } from '../../alerts/alerts';
import { MixpanelService } from '../../../plugins/mixpanel/mixpanel.service';
import { SpaceService } from '../../services/space.service';
import { ProjectService } from '../../services/project.service';

@Component({
  selector: 'app-project-settings',
  templateUrl: './project-settings.component.html',
  styleUrls: ['./project-settings.component.scss'],
  standalone: true,
  imports: [
    TranslocoDirective,
    NgScrollbar,
    NgIf,
    SpaceAvatarComponent,
    CustomSpinnerComponent,
    ProjectAvatarComponent,
    FormsModule,
    NgbDropdown,
    NgbDropdownToggle,
    NgbDropdownMenu,
    NgFor,
    NgbDropdownButtonItem,
    NgbDropdownItem,
    SvgComponent,
    ImageCropperComponent,
    DatePipe,
    CheckPermissionPipe,
  ],
})
export class ProjectSettingsComponent implements OnInit, OnDestroy {
  @ViewChild('avatarInput') avatarInput: ElementRef;
  @ViewChild('imageCropperModal') imageCropperModal: ElementRef;

  @Input() platform = 'web';
  @Input() object: string = null;
  @Input() objectId: string = null;
  @Input() chatId: string = null;
  @Input() spaceId: string = null;
  @Input() isModalWindow = false;
  @Output() close = new EventEmitter<string>();
  @ViewChild('updateForm') updateForm: NgForm;

  maxFileSize = 52428801;
  // TODO: translate
  notifyFilters = [
    {
      value: 'ALL ACTIONS',
      label: this.translocoService.translate('project-settings.all-notifications'),
    },
    {
      value: 'MENTIONS',
      label: this.translocoService.translate('project-settings.only-mentions'),
    },
    {
      value: 'NO CALLS',
      label: this.translocoService.translate('project-settings.no-calls'),
    },
    {
      value: 'NOTHING',
      label: this.translocoService.translate('project-settings.nothing'),
    },
  ];

  destroy$: Subject<void> = new Subject<void>();

  projectImage$: Subscription;
  cropperModal: NgbModalRef;
  isAvatarImageUploading: boolean;
  selectedImage: any;
  avatarImagePreview: any;
  projectAvatarImage: any;

  space: any = null;
  spaceName: string;
  project: any = null;
  initProject: any = null;
  projectName: string;
  projectDesc: string;
  projectSpace: any = null;
  projectEmojiIsOpen = false;
  projectEmojiIsChanged = false;
  projectAvatarIsChanged = false;
  selectedEmoji = '';
  prefix: string;
  selectedFilter: string;
  notifyFilter: string;
  noRingtone: boolean;

  constructor(
    private actions: Actions,
    private store: Store,
    private modalsService: NgbModal,
    private checkPermissionPipe: CheckPermissionPipe,
    private toastrService: ToastrService,
    public cdr: ChangeDetectorRef,
    private translocoService: TranslocoService,
    public readonly spaceService: SpaceService,
    public readonly projectService: ProjectService,
  ) {}

  ngOnInit(): void {
    this.store.dispatch(
      new PushNotificationsSettingsGet({
        object: this.object,
        objectId: this.objectId,
      }),
    );

    setTimeout(() => {
      if (this.avatarInput) {
        this.avatarInput.nativeElement.value = '';
      }
    });

    this.store
      .select(NotificationsState.getFilter)
      .pipe(
        takeUntil(this.destroy$),
        map((filterFn) => filterFn(this.chatId)),
      )
      .subscribe((filters) => {
        this.notifyFilter = filters?.filter || 'ALL ACTIONS';
        this.selectedFilter = this.notifyFilters.find(
          (item) => item.value === this.notifyFilter,
        ).label;
        this.noRingtone = filters?.noRingtone || false;
      });

    if (this.object === 'spaces') {
      this.store
        .select(SpacesState.getSpace)
        .pipe(
          takeUntil(this.destroy$),
          map((filterFn) => filterFn(this.objectId)),
        )
        .subscribe((space) => {
          this.spaceName = space.spaceName;
          this.projectDesc = space.description || '';
          this.prefix = space.prefix;
          this.initProject = space;
          this.avatarImagePreview = space?.avatarUrl || null;
          this.space = { ...space };
          this.store.dispatch(new SpaceSetInfo({ ...space, chatId: this.chatId }));
        });

      this.projectImage$ = this.store
        .select(SpacesState.getSpaceAvatarImageUploadLoading)
        .pipe(
          takeUntil(this.destroy$),
          map((filterFn) => filterFn(this.objectId)),
        )
        .subscribe((res) => {
          this.isAvatarImageUploading = res;
          this.cdr.detectChanges();
        });
    } else if (this.object === 'projects') {
      this.store
        .select(ProjectsState.getProject)
        .pipe(
          takeUntil(this.destroy$),
          map((filterFn) => filterFn(this.objectId)),
        )
        .subscribe((project) => {
          this.projectSpace = this.store.selectSnapshot(SpacesState.getSpace)(project.spaceId);
          this.projectName = project.projectName;
          this.projectDesc = project.description || '';
          this.prefix = project.prefix;

          if (!this.projectEmojiIsChanged && !this.projectAvatarIsChanged) {
            this.initProject = project;
            this.avatarImagePreview = project?.avatarUrl || null;
            this.selectedEmoji = project.emoji;
          } else if (!this.projectEmojiIsChanged) {
            this.avatarImagePreview = this.projectAvatarIsChanged
              ? this.avatarImagePreview
              : project?.avatarUrl;
            this.selectedEmoji = '';
          } else if (!this.projectAvatarIsChanged) {
            this.avatarImagePreview = null;
            this.selectedEmoji = this.projectEmojiIsChanged ? this.selectedEmoji : project.emoji;
          }
          this.project = {
            ...project,
            avatarUrl: this.avatarImagePreview,
            emoji: this.selectedEmoji,
          };

          this.store.dispatch(new ProjectSetInfo({ ...project, chatId: this.chatId }));
        });

      this.projectImage$ = this.store
        .select(ProjectsState.getProjectImageUploadLoading)
        .pipe(
          takeUntil(this.destroy$),
          map((filterFn) => filterFn(this.objectId)),
        )
        .subscribe((res) => {
          this.isAvatarImageUploading = res;
          this.cdr.detectChanges();
        });
    }

    this.actions
      .pipe(takeUntil(this.destroy$), ofActionDispatched(ProjectsSetEmoji))
      .subscribe(({ payload }) => {
        this.projectEmojiIsChanged = true;
        this.projectAvatarIsChanged = false;
        this.projectAvatarImage = null;
        this.selectedImage = null;
        this.avatarInput.nativeElement.value = '';
        this.updateProjectAvatar(null, payload.selectedEmoji);
        this.cdr.markForCheck();
      });
  }

  ngOnDestroy() {
    this.projectImage$?.unsubscribe();
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * Process update project button click
   * @param  {NgForm} form
   */
  updateSubmit(form: NgForm) {
    MixpanelService.trackEvent('Project settings: Update project');
    if (!this.isAdmin()) {
      this.saveSettings();
      return;
    }

    const formData = { ...form.value };
    for (const i of Object.keys(form.value)) {
      formData[i] = formData[i].trim();
    }

    if (
      this.space &&
      (this.spaceName !== formData.spaceName ||
        this.prefix !== formData.prefix ||
        this.avatarImagePreview !== this.initProject.avatarUrl)
    ) {
      this.store
        .dispatch(
          new SpaceUpdate({
            id: this.space._id,
            avatarUrl: this.space?.avatarUrl,
            space: formData,
            currentSpace: this.space,
          }),
        )
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          () => {
            this.updateAvatar();
            this.saveSettings();
          },
          (err) => {
            this.saveSettings();
            this.toastrService.error(
              err.message,
              this.translocoService.translate('toastr.title-error'),
            );
          },
        );
    } else if (
      this.project &&
      (this.projectName !== formData.projectName ||
        this.prefix !== formData.prefix ||
        this.selectedEmoji !== this.initProject.emoji ||
        this.avatarImagePreview !== this.initProject.avatarUrl)
    ) {
      this.store
        .dispatch(
          new ProjectUpdate({
            id: this.project._id,
            avatarUrl: this.project.avatarUrl,
            project: { ...formData, emoji: this.selectedEmoji },
          }),
        )
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          () => {
            this.updateAvatar();
            this.saveSettings();
          },
          (err) => {
            this.saveSettings();
            this.toastrService.error(
              err.message,
              this.translocoService.translate('toastr.title-error'),
            );
          },
        );
    } else {
      this.saveSettings();
    }
  }

  saveSettings() {
    MixpanelService.trackEvent('Project settings: Save settings');
    const body = {
      object: this.object,
      objectId: this.objectId,
      filter: this.notifyFilter,
      noRingtone: this.noRingtone,
    };

    this.store
      .dispatch(new PushNotificationsSettingsCreate(body))
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.store.dispatch(new ChatsGet());
        if (this.isModalWindow) {
          this.close.emit('saved');
        }

        this.toastrService.success(
          this.translocoService.translate('toastr.notifications-settings-updated'),
          this.translocoService.translate('toastr.title-settings'),
        );
      });

    if (this.object === 'spaces') {
      ConfirmAlert(null, {
        subject: this.translocoService.translate(
          'toastr.notifications-settings-apply-for-all-subject',
        ),
        text: this.translocoService.translate('toastr.notifications-settings-apply-for-all-text'),
        cancelButtonText: 'no',
        showDenyButton: false,
        confirmButtonText: 'yes',
        confirmButtonClass: 'btn-solid',
        platform: this.platform,
      }).then(
        (result) => {
          if (result === 'isConfirmed') {
            this.store
              .dispatch(new ChatsGet({ spaceId: this.objectId }))
              .pipe(takeUntil(this.destroy$))
              .subscribe((store) => {
                for (const chat of store.Chats.chats) {
                  if (chat.spaceId === this.objectId || chat.type === 'group') {
                    let objectType = chat.object || 'group';
                    const body = {
                      object: objectType,
                      objectId: objectType === 'group' ? chat._id : chat.objectId,
                      filter: this.notifyFilter,
                      noRingtone: this.noRingtone,
                    };

                    this.store
                      .dispatch(new PushNotificationsSettingsCreate(body))
                      .pipe(takeUntil(this.destroy$))
                      .subscribe(() => {
                        if (this.isModalWindow) {
                          this.close.emit('saved');

                          this.toastrService.success(
                            this.translocoService.translate(
                              'toastr.notifications-settings-apply-for-all-subject',
                            ),
                          );
                        }
                      });
                  }
                }

                this.store.dispatch(new ChatsGet());
              });
          }
        },
        () => {},
      );
    }

    this.store.dispatch(new ChatsGet());
  }

  updateAvatar() {
    MixpanelService.trackEvent('Project settings: Update avatar');
    if (this.projectAvatarImage) {
      if (this.projectAvatarImage.size < this.maxFileSize) {
        const body = { id: this.objectId, file: this.projectAvatarImage };
        this.store
          .dispatch(this.space ? new SpaceAvatarSet(body) : new ProjectAvatarSet(body))
          .pipe(takeUntil(this.destroy$))
          .subscribe(
            () => {
              this.avatarInput.nativeElement.value = '';
              this.refreshChatMenu();
            },
            (err) => {
              this.avatarInput.nativeElement.value = '';
              this.toastrService.error(
                err.message,
                this.translocoService.translate('toastr.title-error'),
              );
            },
          );
      } else {
        this.toastrService.error(
          this.translocoService.translate('toastr.err-message-file-size', {
            size: '50MB',
          }),
          this.projectAvatarImage.name,
        );
      }
    } else if (
      !this.avatarInput.nativeElement.value &&
      ((this.space && !this.space.avatarUrl) || (this.project && !this.project.avatarUrl))
    ) {
      this.store
        .dispatch(
          new AvatarDelete({
            type: 'avatar',
            object: this.object,
            objectId: this.objectId,
          }),
        )
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          () => this.refreshChatMenu(),
          (err) =>
            this.toastrService.error(
              err.message,
              this.translocoService.translate('toastr.title-error'),
            ),
        );
    } else {
      this.refreshChatMenu();
    }
  }

  refreshChatMenu() {
    const object = this.object === 'spaces' ? 'Space' : 'Project';
    this.store.dispatch(new ChatsGet());

    this.toastrService.success(this.translocoService.translate('toastr.title-success'));
  }

  selectFilter(item) {
    this.notifyFilter = item.value;
    this.selectedFilter = item.label;
  }

  toggleNoRingtone(isChecked) {
    MixpanelService.trackEvent('Project settings: Toggle no ringtone');
    this.noRingtone = isChecked;
  }

  isAdmin(): boolean {
    const value = this.space?._id
      ? 'spaces::' + this.space._id + '::spacesUpdate'
      : this.project?._id
        ? 'projects::' + this.project._id + '::projectUpdate'
        : null;
    return this.checkPermissionPipe.transform(value);
  }

  /**
   * Update avatar
   */
  setProjectAvatar(file) {
    this.projectAvatarImage = file;
  }

  updateProjectAvatar(avatarUrl, emoji) {
    this.avatarImagePreview = avatarUrl;
    if (this.space) {
      this.space = { ...this.space, avatarUrl };
    } else if (this.project) {
      this.selectedEmoji = emoji;
      this.project = { ...this.project, avatarUrl, emoji };
    }
  }

  setImagePreview(event) {
    this.projectEmojiIsChanged = false;
    this.projectAvatarIsChanged = true;
    this.updateProjectAvatar(event, '');
  }

  getAvatarImageName() {
    return this.selectedImage?.target?.files[0]?.name;
  }

  avatarChangeEvent(event) {
    this.selectedImage = event;

    if (this.getAvatarImageName()) {
      this.cropperModal = this.modalsService.open(this.imageCropperModal, {
        backdrop: 'static',
        keyboard: false,
        windowClass: 'cropper-modal',
      });
    }
  }

  closeCropperModal() {
    this.cropperModal.close();
    this.avatarInput.nativeElement.value = '';
  }

  projectEmojiPickerToggle(e) {
    e.stopPropagation();
    this.store.dispatch(
      new ProjectsEmojiPicker({
        projectEmojiPickerIsOpen: !this.projectEmojiIsOpen,
      }),
    );
  }

  clearProjectEmoji(e) {
    e.stopPropagation();
    this.store.dispatch(new ProjectsSetEmoji({ selectedEmoji: '' }));
  }

  deleteAvatarImage() {
    this.projectAvatarImage = null;
    this.avatarImagePreview = null;
    this.selectedImage = null;
    this.avatarInput.nativeElement.value = '';
    this.updateProjectAvatar(null, '');
  }
}
