import { Injectable, NgZone } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { NotificationsPaginationRes } from '../models/notifications-pagination-res';
import * as ssePolyfill from 'event-source-polyfill/src/eventsource.min.js';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient,
    private zone: NgZone,
  ) {
    super(config, http);
  }

  /**
   * Path part for operation notificationsGetList
   */
  static readonly NotificationsGetListPath = '/notifications';
  static readonly MarkNotificationsAsReadPath = '/notifications/mark-read';

  notificationsGetList(
    page: number = 1,
    limit: number = 10,
    unread?: boolean,
  ): Observable<NotificationsPaginationRes> {
    let params = new HttpParams().set('page', page.toString()).set('limit', limit.toString());

    if (unread) {
      params = params.set('unread', unread);
    }

    return this.http.get<NotificationsPaginationRes>(
      `${this.rootUrl}${NotificationsService.NotificationsGetListPath}`,
      { params },
    );
  }

  markNotificationsAsRead(id?: string): Observable<any> {
    const data: any = id ? { id } : {};
    return this.http.patch(
      `${this.rootUrl}${NotificationsService.MarkNotificationsAsReadPath}`,
      data,
    );
  }
}
