import {
  EventEmitter,
  ChangeDetectorRef,
  ElementRef,
  Component,
  Input,
  Output,
  ViewChild,
  OnInit,
  OnDestroy,
  AfterViewInit,
  forwardRef,
} from '@angular/core';
import { Store } from '@ngxs/store';
import { combineLatest, Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

import { REGEXP_EMAIL } from '../../data/regexp';
import { InvitedMembers } from '../../data/object-creation';
import { ConfigService } from '../../services/config.service';
import { RolesGet } from '../../store/actions/roles.action';
import { TenantInvite } from '../../store/actions/auth.action';
import { GetUsersListByTenant } from '../../store/actions/users.action';
import { SpaceGetUsersList, SpaceInvite } from '../../store/actions/spaces.action';
import { ProjectGetUsersList, ProjectInvite } from '../../store/actions/projects.action';
import { AuthState } from '../../store/states/auth.state';
import { UsersState } from '../../store/states/users.state';
import { RolesState } from '../../store/states/roles.state';
import { SpacesState } from '../../store/states/spaces.state';
import { ProjectsState } from '../../store/states/projects.state';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TenantsState } from '../../store/states/tenants.state';
import { Capacitor } from '@capacitor/core';
import { TranslocoService, TranslocoDirective } from '@ngneat/transloco';
import { TruncatePipe } from '../../pipes/truncate.pipe';
import { SvgComponent } from '../../svgs/svg/svg.component';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgScrollbar } from 'ngx-scrollbar';
import { AvatarComponent } from '../../../standalone/components/avatar/avatar.component';
import { AutocompleteComponent, AutocompleteLibModule } from 'angular-ng-autocomplete';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';
import { MixpanelService } from '../../../plugins/mixpanel/mixpanel.service';

@Component({
  selector: 'app-members-invite',
  templateUrl: './members-invite.component.html',
  styleUrls: ['./members-invite.component.scss'],
  standalone: true,
  imports: [
    TranslocoDirective,
    NgIf,
    AutocompleteLibModule,
    forwardRef(() => AvatarComponent),
    NgScrollbar,
    NgFor,
    NgSelectModule,
    FormsModule,
    SvgComponent,
    AsyncPipe,
    TruncatePipe,
  ],
})
export class MembersInviteComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('autocomplete') autocomplete: AutocompleteComponent;
  @ViewChild('roleSelect') roleSelect: ElementRef<any>;

  @Input() platform: string;
  @Input() object: string;
  @Input() objectId: string;
  @Input() parentSpaceId: string;
  @Input() objectCreating = false;
  @Input() usersInEditMode = false;

  @Output() inviteObjectMembers = new EventEmitter<InvitedMembers>();

  destroy$: Subject<void> = new Subject<void>();
  config: any = {};
  allTenantMembers: any;
  currentEntityMembers: any;
  candidateEntityMembers: any;
  currentUser: any;
  searchedInput: string;
  keyword = 'userName_email';
  selectedMember;
  selectedRole;
  isSelectedExist = false;
  roles: Observable<any[]>;
  inviteCompleted = false;
  mobileOs: string;
  invitedMembersList = [];
  tenantName: string;
  parentSpaceName: string;
  enteredEmailListLengthLimit = 50;

  constructor(
    private cdr: ChangeDetectorRef,
    private store: Store,
    private toastr: ToastrService,
    private configService: ConfigService,
    private activeModal: NgbActiveModal,
    private translocoService: TranslocoService,
  ) {
    this.config = this.configService.templateConf;
  }

  /**
   * Called on invite return
   */
  private onInviteCompleted() {
    this.inviteCompleted = true;
    if (this.object === 'spaces') {
      this.store.dispatch(new SpaceGetUsersList({ id: this.objectId, exists: true }));
    } else if (this.object === 'projects') {
      this.store.dispatch(new ProjectGetUsersList({ id: this.objectId, exists: true }));
    }
    this.cdr.detectChanges();
  }

  ngOnInit() {
    this.mobileOs = Capacitor.getPlatform();
    const prepareTenantMembers = (usersArr) => {
      return usersArr.map((user) => ({
        ...user,
        userName_email: `${user.userName}  (${user.email})`,
      }));
    };

    const usersListByTenant$ = this.store.dispatch(new GetUsersListByTenant());
    const tenantUsers$ = this.store.select(UsersState.getTenantUsers);
    const currentUser$ = this.store.select(AuthState.getUser);

    combineLatest([usersListByTenant$, tenantUsers$, currentUser$])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([_, tenantUsers, currentUser]) => {
        this.allTenantMembers = prepareTenantMembers(tenantUsers);
        this.currentUser = currentUser;
        this.initMainLogic();
      });
    MixpanelService.trackEvent('Members Invite: Open');
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public ngAfterViewInit() {
    if (this.platform === 'web') {
      this.autocompleteFocus();
    }

    this.configService.templateConf$.pipe(takeUntil(this.destroy$)).subscribe((templateConf) => {
      if (templateConf) {
        this.config = templateConf;
        this.cdr.detectChanges();
      }
    });
  }

  /**
   * Invite users button click handler
   */
  usersInviteClick() {
    MixpanelService.trackEvent('Members Invite: Invite');
    if (this.isRoleOfInvitedMembersNull()) {
      this.toastr.error(
        this.translocoService.translate('toastr.all-selected-invitees-must-have-a-role'),
      );
    } else {
      if (this.object === 'spaces') {
        if (this.objectCreating) {
          this.inviteObjectMembers.emit({
            newUsers: this.prepareNewUser(),
            existingUsers: this.prepareExistingUser(),
            invitedMembersList: this.invitedMembersList,
          });
        } else {
          this.store
            .dispatch(
              new SpaceInvite({
                spaceId: this.objectId,
                newUsers: this.prepareNewUser(),
                existingUsers: this.prepareExistingUser(),
              }),
            )
            .pipe(takeUntil(this.destroy$))
            .subscribe(
              () => this.onInviteCompleted(),
              (err) => {
                this.toastr.error(
                  err.message,
                  this.translocoService.translate('toastr.title-error'),
                );
              },
            );
        }
      } else if (this.object === 'projects') {
        if (this.objectCreating) {
          this.inviteObjectMembers.emit({
            newUsers: this.prepareNewUser(),
            existingUsers: this.prepareExistingUser(),
            invitedMembersList: this.invitedMembersList,
          });
        } else {
          this.store
            .dispatch(
              new ProjectInvite({
                projectId: this.objectId,
                newUsers: this.prepareNewUser(),
                existingUsers: this.prepareExistingUser(),
              }),
            )
            .pipe(takeUntil(this.destroy$))
            .subscribe(
              () => this.onInviteCompleted(),
              (err) => {},
            );
        }
      } else if (this.object === 'tenants') {
        this.store
          .dispatch(new TenantInvite({ newUsers: this.prepareNewUser() }))
          .pipe(takeUntil(this.destroy$))
          .subscribe(
            () => this.onInviteCompleted(),
            (err) => {},
          );
      }
    }
  }

  setCandidateMembers(users) {
    this.currentEntityMembers = users;
    const currentMembersIndices = [];

    if (this.objectId) {
      // skip this logic on new object
      this.allTenantMembers.forEach((tenantMember, index) => {
        this.currentEntityMembers.forEach((currentMember) => {
          if (tenantMember._id === currentMember._id) {
            currentMembersIndices.push(index);
          }
        });
      });
    }

    this.candidateEntityMembers = this.allTenantMembers.filter(
      (member, index) =>
        currentMembersIndices.indexOf(index) === -1 &&
        member._id !== this.currentUser._id &&
        !member.isAssistant,
    );
  }

  selectEvent(item, isEnter = false) {
    if (item.type === 'keydown' && this.mobileOs !== 'ios') {
      return;
    }

    if (
      this.currentEntityMembers.some((entityMember) => entityMember.email === this.searchedInput)
    ) {
      this.toastr.info(
        this.translocoService.translate('toastr.user-already-invited', {
          email: this.searchedInput,
        }),
      );
      return;
    }

    let listOfUniqueEmails;
    if (this.searchedInput) {
      listOfUniqueEmails = [...new Set(this.searchedInput.split(';').map((email) => email.trim()))];
    }
    if (listOfUniqueEmails?.length > 1) {
      this.onEnterEmailList(item, listOfUniqueEmails);
      return;
    }

    if (!isEnter) {
      this.isSelectedExist = this.candidateEntityMembers.some((user) => user.email === item.email);
    } else if (this.isSelectedExist) {
      this.isSelectedExist = false;
      return;
    }
    if (
      this.invitedMembersList.some((member) => {
        let isExistingUserAlreadyInTheList;
        if (member._id && this.searchedInput) {
          isExistingUserAlreadyInTheList = this.allTenantMembers.some(
            (tenantMember) =>
              tenantMember._id === member._id && tenantMember.email === this.searchedInput,
          );
        }

        return (
          member.userName === item.userName ||
          member.email === item.userName ||
          member.email === this.searchedInput ||
          isExistingUserAlreadyInTheList
        );
      })
    ) {
      this.toastr.info(
        this.translocoService.translate('toastr.user-already-in-the-list', {
          email: this.searchedInput,
        }),
      );
      return;
    }
    if (!isEnter) {
      this.selectedMember = item;
      this.addMemberToInvitedList();
    } else if (this.selectedMember) {
      this.addMemberToInvitedList();
    } else {
      this.toastr.info(
        this.translocoService.translate('toastr.select-member-from-the-suggestion-list'),
      );
    }
  }

  onChangeSearch(input: string) {
    this.searchedInput = input;

    if (this.isValidEmail(input)) {
      this.selectedMember = {
        userName: input,
        isExternalUser: true,
      };
    }
  }

  isValidEmail(input) {
    return REGEXP_EMAIL.test(input);
  }

  onCleared(e) {
    this.searchedInput = '';
    this.selectedMember = '';
    this.autocomplete.close();
  }

  onOptionsSelected(value: string) {
    this.selectedRole = value;
  }

  inviteAllCandidates(checkbox): void {
    if (checkbox.target.checked) {
      this.candidateEntityMembers.forEach((member) => {
        if (this.object === 'projects') {
          if (member.roles.find((obj) => obj.objectId === this.parentSpaceId)) {
            this.selectedMember = member;
            this.addMemberToInvitedList();
          }
        } else {
          this.selectedMember = member;
          this.addMemberToInvitedList();
        }
      });
    } else {
      this.invitedMembersList.forEach((member, index) => {
        this.removeInvitedMember(member, index);
      });
    }
  }

  addMemberToInvitedList() {
    MixpanelService.trackEvent('Members Invite: Add member to invited list');
    this.invitedMembersList.reverse();
    this.invitedMembersList.push({
      _id: this.selectedMember._id,
      userName: this.selectedMember.userName,
      email: this.selectedMember.userName,
      roleName: this.object === 'projects' ? 'Project Member' : 'Space Member',
      isExternalUser: this.selectedMember.isExternalUser,
    });

    this.invitedMembersList.reverse();

    if (this.selectedMember._id) {
      this.candidateEntityMembers = this.candidateEntityMembers.filter(
        (item) => item._id !== this.selectedMember._id,
      );
    }

    this.selectedMember = '';
    this.selectedRole = '';
    this.autocomplete.clear();
  }

  removeInvitedMember(member, memberIndex) {
    if (member._id) {
      this.invitedMembersList = this.invitedMembersList.filter((item) => item._id !== member._id);

      this.candidateEntityMembers = [
        ...this.candidateEntityMembers,
        this.allTenantMembers.filter((item) => item._id === member._id).pop(),
      ];
    } else {
      this.invitedMembersList = this.invitedMembersList.filter((_, index) => index !== memberIndex);
    }
  }

  autocompleteFocus() {
    setTimeout(() => {
      this.autocomplete?.focus();
    }, 0);
  }

  prepareExistingUser() {
    return this.invitedMembersList
      .filter((user) => !user.isExternalUser)
      .map((user) => {
        return {
          userId: user._id,
          roleName: user.roleName,
        };
      });
  }

  prepareNewUser() {
    return this.invitedMembersList
      .filter((user) => user.isExternalUser)
      .map((user) => {
        return {
          email: user.userName,
          roleName: user.roleName,
        };
      });
  }

  isRoleOfInvitedMembersNull() {
    const invitedMembersWithNoRole = this.invitedMembersList.filter(
      (member) => member.roleName == null,
    );

    return invitedMembersWithNoRole.length !== 0;
  }

  close() {
    MixpanelService.trackEvent('Members Invite: Close');
    this.activeModal.close();
  }

  onPaste(event: ClipboardEvent) {
    MixpanelService.trackEvent('Members Invite: Paste');
    const pastedText = event.clipboardData.getData('text');

    const filterText = this.candidateEntityMembers.filter((user) =>
      user.email.includes(pastedText),
    );

    this.autocomplete.toHighlight = pastedText;
    this.autocomplete.filteredList = filterText;
    this.autocomplete.notFound = !filterText.length;

    this.onChangeSearch(pastedText);
  }

  onEnterEmailList(event: KeyboardEvent, listOfUniqueEmails: string[]) {
    MixpanelService.trackEvent('Members Invite: Enter email list');
    if (listOfUniqueEmails.length > this.enteredEmailListLengthLimit) {
      this.toastr.info(
        this.translocoService.translate('toastr.email-list-length-limit', {
          limit: this.enteredEmailListLengthLimit,
        }),
      );
      return;
    }

    this.searchedInput = '';
    listOfUniqueEmails.forEach((email) => {
      if (!this.isValidEmail(email)) {
        return;
      }
      this.searchedInput = email;
      const userFromCandidate = this.candidateEntityMembers.find((user) => user.email === email);
      this.isSelectedExist = !!userFromCandidate;
      if (this.isSelectedExist) {
        this.selectEvent(userFromCandidate, false);
      } else {
        this.onChangeSearch(email);
      }
      this.selectEvent(event, true);
    });
  }

  initMainLogic() {
    this.store.dispatch(new RolesGet());
    this.roles = this.store.select(RolesState.getRoles).pipe(
      takeUntil(this.destroy$),
      map((items) => items.filter((item) => item.object === this.object)),
    );

    this.tenantName = this.store.selectSnapshot(TenantsState.getTenantName);

    if (this.objectId) {
      if (this.object === 'spaces') {
        this.store.dispatch(new SpaceGetUsersList({ id: this.objectId, exists: true }));
        this.store
          .select(SpacesState.getUsers)
          .pipe(takeUntil(this.destroy$))
          .subscribe((result) => this.setCandidateMembers(result));
      } else if (this.object === 'projects') {
        this.store.dispatch(new ProjectGetUsersList({ id: this.objectId, exists: true }));
        this.store
          .select(ProjectsState.getUsers)
          .pipe(takeUntil(this.destroy$))
          .subscribe((result) => this.setCandidateMembers(result));
      }
    } else {
      if (this.object === 'spaces') {
        this.store
          .select(SpacesState.getUsers)
          .pipe(takeUntil(this.destroy$))
          .subscribe((result) => this.setCandidateMembers(result));

        this.store
          .select(SpacesState.getLastCreatedId)
          .pipe(takeUntil(this.destroy$))
          .subscribe((res) => {
            if (res !== '') {
              this.objectId = res;
              this.store.dispatch(new SpaceGetUsersList({ id: res, exists: false }));
            }
          });
      } else if (this.object === 'projects') {
        this.store.dispatch(new SpaceGetUsersList({ id: this.parentSpaceId, exists: false }));
        this.parentSpaceName = this.store.selectSnapshot(SpacesState.getSpace)(
          this.parentSpaceId,
        ).spaceName;

        this.store
          .select(SpacesState.getUsers)
          .pipe(takeUntil(this.destroy$))
          .subscribe((result) => this.setCandidateMembers(result));

        this.store
          .select(ProjectsState.getLastCreatedId)
          .pipe(takeUntil(this.destroy$))
          .subscribe((res) => {
            if (res !== '') {
              this.objectId = res;
              this.store.dispatch(new ProjectGetUsersList({ id: res, exists: true }));
            }
          });
      }
    }
  }
}
