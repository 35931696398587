import { ThreadMessagesCreateReqDto } from '../../../api/models/thread-messages-create-req-dto';

export class ThreadsSetCurrentThreadHeadMessage {
  static readonly type = '[Threads] Set Current Thread Head Message Data';
  constructor(public payload) {}
}

export class ThreadsSetMessages {
  static readonly type = '[Threads] Set Messages';
  constructor(public payload) {}
}

export class ThreadsSocketNewMessage {
  static readonly type = '[Threads] Socket New Message';
  constructor(public payload) {}
}

export class ThreadsSocketNewMessageInit {
  static readonly type = '[Threads] Socket New Message Init';
  constructor(public payload) {}
}

export class ThreadsClearCurrentThreadHeadMessage {
  static readonly type = '[Threads] Clear';
}

export class ThreadsSocketUpdatedMessage {
  static readonly type = '[Threads] Socket Updated Message';
  constructor(public payload) {}
}

export class ThreadsUploadFile {
  static readonly type = '[Threads] Upload File';
  constructor(public payload) {}
}

export class ThreadsUpdateMessageDraft {
  static readonly type = '[Threads] Update Messages Draft';
  constructor(public payload) {}
}

export class ThreadsSetCurrentPage {
  static readonly type = '[Threads] Set Current Page';
  constructor(public payload) {}
}

export class ThreadsNewMessageToUpload {
  static readonly type = '[Threads] New Message To Upload';
  constructor(public payload) {}
}

export class ThreadsCloseSidebarOnDelete {
  static readonly type = '[Threads] Close Sidebar';
}

export class ThreadsClearData {
  // TODO: Remove after properly reconnect messages sync will be implemented
  static readonly type = '[Threads] Clear All Threads Data';
}

export class ThreadCreateWiki {
  static readonly type = '[Threads] Create wiki';
  constructor(public payload: any) {}
}

export class ThreadMessageCreate {
  static readonly type = '[Threads] ThreadMessageCreate';
  constructor(public payload: ThreadMessagesCreateReqDto | any) {}
}

export class MarkAllThreads {
  static readonly type = '[Threads] MarkAllThreads';
  constructor(public payload?: { chatId: string }) {}
}

export class MarkThreadAsUnread {
  static readonly type = '[Threads] MarkAsUnread';
  constructor(public payload: any) {}
}

export class MarkThreadAsRead {
  static readonly type = '[Threads] MarkAsRead';
  constructor(public payload: any) {}
}
