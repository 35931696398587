import { WebhookCreateUpdateReqDto } from '../../../api/models/webhook-create-update-req-dto';

/** Get webhooks by chat id action **/
export class WebhooksByChatIdGet {
  static readonly type = '[Webhooks] Get webhooks by chat id';
  constructor(public chatId: string) {}
}

/** Create webhook for chat **/
export class WebhookCreate {
  static readonly type = '[Webhooks] Create a webhook for the chat';
  constructor(public payload: WebhookCreateUpdateReqDto) {}
}

/** Delete webhook for chat **/
export class WebhookDelete {
  static readonly type = '[Webhooks] Delete the webhook by the id';
  constructor(public payload: { id: string; chatId: string }) {}
}

/** Update webhook for chat **/
export class WebhookUpdate {
  static readonly type = '[Webhooks] Update the webhook by the id';
  constructor(public payload: WebhookCreateUpdateReqDto) {}
}

/** Clear createdWebhook data in state **/
export class ClearCreatedWebhook {
  static readonly type = '[Webhooks] Clear createdWebhook data';
  constructor() {}
}
