import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class EmojisHelper {
  static categorizeEmojis(emojis: any[]) {
    return [...new Set(emojis.map((item) => item.emojiName))].map((item) => ({
      emojiName: item,
      messageObject: null,
      users: [],
      isCurrentUserReaction: false,
    }));
  }

  static aggregateEmojis(username: string, emojis: any[], emojiId: string) {
    const categorizedEmojis = EmojisHelper.categorizeEmojis(emojis);

    categorizedEmojis.forEach((item) => {
      emojis.forEach((emoji) => {
        if (item.emojiName === emoji.emojiName) {
          if (emoji._id !== emojiId) {
            item.users = [...item.users, emoji.userName];
            item.messageObject = emoji.messageObject;
            if (emoji.userName === username) {
              item.isCurrentUserReaction = true;
            }
          }
        }
      });
    });

    return categorizedEmojis.filter((item) => item.users.length > 0);
  }

  aggregateIdenticalEmojis(username: string, emojis: any[]) {
    const categorizedEmojis = EmojisHelper.categorizeEmojis(emojis);

    categorizedEmojis.forEach((item) => {
      emojis.forEach((emoji) => {
        if (item.emojiName === emoji.emojiName) {
          item.users = [...item.users, emoji.userName];
          item.messageObject = emoji.messageObject;
          if (emoji.userName === username) {
            item.isCurrentUserReaction = true;
          }
        }
      });
    });

    return categorizedEmojis;
  }
}
