export const PICKED_OBJECT_COLOR = '#6D43D3';
export const OBJECT_COLORS = [
  '#EA4040',
  '#EA7D40',
  '#EDB341',
  '#EBC43A',
  '#B5CE1E',
  '#6FC716',
  '#22A71F',
  '#1CB096',
  '#1CA7B0',
  '#24B6E4',
  '#247CE4',
  '#1C4CC8',
  '#4C35DA',
  '#6D43D3',
  '#6D43D3',
  '#D043D3',
  '#D623AE',
  '#C9176C',
];

export const DEFAULT_AVATARS = [
  'assets/img/avatars/1.png',
  'assets/img/avatars/2.png',
  'assets/img/avatars/3.png',
  'assets/img/avatars/4.png',
  'assets/img/avatars/5.png',
  'assets/img/avatars/6.png',
  'assets/img/avatars/7.png',
  'assets/img/avatars/8.png',
  'assets/img/avatars/9.png',
  'assets/img/avatars/10.png',
];

export interface InvitedMembers {
  newUsers: any[];
  existingUsers: any[];
  invitedMembersList?: any[];
}
