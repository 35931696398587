import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { Subject } from 'rxjs';

import { AuthState } from '../../shared/store/states/auth.state';
import { ConfigService } from '../../shared/services/config.service';
import { TranslocoDirective } from '@ngneat/transloco';

export interface InfoPage {
  space?: {};
}

@Component({
  selector: 'app-space-delete-info',
  templateUrl: './space-delete-info.component.html',
  styleUrls: ['./space-delete-info.component.scss'],
  standalone: true,
  imports: [TranslocoDirective],
})
export class SpaceDeleteInfoComponent implements OnInit, OnDestroy {
  destroy$: Subject<void> = new Subject<void>();
  InfoData: InfoPage;
  platform: string;
  space: any;
  config: any = {};

  constructor(
    private store: Store,
    private activeModal: NgbActiveModal,
    private configService: ConfigService,
  ) {}

  ngOnInit(): void {
    this.initModalData(this.InfoData);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  close() {
    this.activeModal.close();
  }

  initModalData(data: InfoPage): void {
    this.config = this.configService.templateConf;
    this.platform = this.store.selectSnapshot(AuthState.getPlatform);
    this.space = data?.space;
  }
}
