<ng-container *transloco="let t; read: 'member-invite'">
  <div *ngIf="inviteCompleted">
    <div class="row justify-content-center">
      <div class="col-12">
        <div class="invitation-sent" t-id="members-invite-alert">
          <img
            src="assets/img/svg/invitation-sent-{{
              config?.layout.variant === 'Light' ? 'light' : 'dark'
            }}.svg"
            class="image"
            alt="invitation-sent"
          />
          <div class="message">{{ t('invite-success') }}</div>
          <button class="btn btn-large btn-subtle" (click)="close()">
            {{ t('invite-close') }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="!inviteCompleted" class="invitation-list-wrapper">
    <ng-autocomplete
      t-id="members-invite-add-email-input"
      #autocomplete
      [placeholder]="t('invite-placeholder')"
      [notFoundText]="t('invite-not-found')"
      [focusFirst]="true"
      [data]="candidateEntityMembers"
      [searchKeyword]="keyword"
      [itemTemplate]="itemTemplate"
      [notFoundTemplate]="notFoundTemplate"
      (inputChanged)="onChangeSearch($event)"
      (inputCleared)="onCleared($event)"
      (keyup.enter)="selectEvent($event, true)"
      (keydown.enter)="selectEvent($event, true)"
      (paste)="onPaste($event)"
      (selected)="selectEvent($event)"
    ></ng-autocomplete>

    <div *ngIf="objectCreating" class="members-invite-all d-flex align-middle">
      <input type="checkbox" class="mt-1 mr-1" (change)="inviteAllCandidates($event)" />
      {{
        t('invite-checkbox', {
          value: object === 'projects' ? parentSpaceName : tenantName,
          object: object === 'projects' ? t('invite-space') : t('invite-workplace')
        })
      }}
    </div>

    <ng-template #itemTemplate let-item>
      <div class="d-flex align-items-center p-1">
        <app-avatar
          [userId]="item._id"
          [width]="40"
          [height]="40"
          [showStatus]="false"
        ></app-avatar>
        <div class="invited-members-container">
          <div class="invited-members-username no-mb" [innerHTML]="item.userName"></div>
          <div class="invited-members-email">{{ item.email }}</div>
        </div>
      </div>
    </ng-template>

    <ng-template #notFoundTemplate let-notFound>
      <div [innerHTML]="notFound"></div>
    </ng-template>

    <div
      *ngIf="invitedMembersList.length === 0 && !objectCreating"
      class="invitation-empty-state invitation-list"
    >
      <img
        src="assets/img/svg/invite-empty-state-{{
          config?.layout.variant === 'Light' ? 'light' : 'dark'
        }}.svg"
        class="image"
        alt="empty-state"
      />
    </div>

    <!--PERFECT-->
    <div *ngIf="invitedMembersList.length" class="invited-members-list invitation-list">
      <ng-scrollbar [autoHeightDisabled]="false" visibility="hover" thumbClass="white-scrollbars">
        <div
          *ngFor="let member of invitedMembersList; index as i"
          class="invited-members-list-item"
        >
          <div class="d-flex align-items-center member-details-container">
            <i
              *ngIf="member.isExternalUser"
              class="fa fa-envelope-o envelope-icon"
              aria-hidden="true"
            ></i>
            <app-avatar
              *ngIf="!member.isExternalUser"
              [userId]="member._id"
              [width]="44"
              [height]="44"
              [showStatus]="false"
            ></app-avatar>
            <div>
              <div class="invited-members-username">
                {{ member?.userName | limitTo: (platform !== 'web' ? 12 : 20) }}
              </div>
              <div class="invited-members-email">
                {{ member?.email | limitTo: (platform !== 'web' ? 12 : 20) }}
              </div>
            </div>
            <!-- <div *ngIf="member.isExternalUser" class="invitation-pending">(invitation pending)</div> -->
          </div>

          <ng-select
            t-id="members-invite-select-role"
            class="select-role"
            bindLabel="roleName"
            bindValue="roleName"
            appendTo="body"
            [placeholder]="t('invite-role')"
            [(ngModel)]="member.roleName"
            [items]="roles | async"
            [clearable]="false"
            [searchable]="false"
            [closeOnSelect]="true"
          ></ng-select>

          <app-svg
            (click)="removeInvitedMember(member, i)"
            name="times"
            width="10"
            height="10"
            class="svg-icon"
            aria-hidden="true"
          ></app-svg>
        </div>
      </ng-scrollbar>
    </div>

    <div *ngIf="objectCreating" class="invite-modal-footer invite-modal-footer_creation">
      <ng-content select="[previous-step-button]"></ng-content>
      <button
        t-id="members-invite-save-action"
        type="button"
        class="btn btn-solid btn-modal"
        [class.w-100]="usersInEditMode"
        (click)="usersInviteClick()"
      >
        {{ usersInEditMode ? t('invite-confirm') : t('invite-next') }}
      </button>
    </div>

    <div *ngIf="!objectCreating && invitedMembersList.length" class="invite-modal-footer">
      <ng-content select="[previous-step-button]"></ng-content>
      <button
        t-id="members-invite-invite-action"
        type="button"
        class="btn btn-solid"
        (click)="usersInviteClick()"
      >
        {{ t('invite-btn') }}
      </button>
    </div>
  </div>
</ng-container>
