<!-- image cropper modal -->
<ng-template
  #imageCropperModal
  let-c="close"
  let-d="dismiss"
  *transloco="let t; read: 'modals.space'"
>
  <div class="modal-header">
    <h5 class="modal-title">{{ t('title-edit-image') }}</h5>
    <button type="button" class="close" aria-label="Close" (click)="closeCropperModal()">
      <app-svg name="times" width="10" height="10" class="svg-icon" aria-hidden="true"></app-svg>
    </button>
  </div>
  <div class="modal-body">
    <app-image-cropper
      [image]="imageChangedEvent"
      [aspectRatio]="1"
      [resizeToWidth]="128"
      (avatarImagePreview)="setImagePreview($event)"
      (avatarImageFile)="setSpaceImageFile($event)"
      (closeCropperModal)="closeCropperModal()"
    ></app-image-cropper>
  </div>
</ng-template>

<ng-container *transloco="let t; read: 'modals.space'">
  <!-- space create modal -->
  <div class="modal-header">
    <button type="button" class="close" aria-label="Close" (click)="closeCreateSpaceModal()">
      <app-svg name="times" width="10" height="10" class="svg-icon" aria-hidden="true"></app-svg>
    </button>
  </div>
  <div class="modal-body">
    <section id="ngx">
      <aw-wizard #wizard navBarLocation="bottom" [disableNavigationBar]="true">
        <aw-wizard-step class="wizard-step wizard-step_wrapper">
          <div class="creation-pic">
            <img src="assets/img/space-creation.png" alt="space" />
          </div>
          <div class="creation-title">
            {{ t('title-create-new-space') }}
          </div>
          <div class="creation-hint">
            {{ t('creation-hint') }}
          </div>
          <form #form_step1="ngForm" class="editForm" novalidate>
            <div class="row d-flex justify-content-center mt-1">
              <div class="col-12">
                <div class="form-group">
                  <label class="creation-label" for="spaceName">{{ t('form-space-name') }}</label>
                  <input
                    t-id="space-create-name"
                    class="form-control form-control_creation input-md"
                    type="text"
                    name="spaceName"
                    id="spaceName"
                    [placeholder]="t('type-something-placeholder')"
                    #spaceName="ngModel"
                    ngModel
                    required
                    [appFocus]="platform === 'web'"
                  />
                  <small
                    class="form-text danger"
                    *ngIf="!spaceName.valid && (spaceName.dirty || spaceName.touched)"
                    >{{ t('form-required') }}</small
                  >
                </div>
                <div class="form-group">
                  <label class="creation-label" for="spaceName">{{ t('form-space-prefix') }}</label>
                  <input
                    t-id="space-create-prefix"
                    class="form-control form-control_creation input-md"
                    type="text"
                    name="prefix"
                    id="spacePrefix"
                    #spacePrefix="ngModel"
                    ngModel
                    required
                    maxlength="3"
                    [placeholder]="t('space-prefix-placeholder')"
                    pattern="^[A-Za-z0-9]{1,3}$"
                  />
                  <small
                    class="form-text danger"
                    *ngIf="!spacePrefix.valid && (spacePrefix.dirty || spacePrefix.touched)"
                  >
                    {{ t('form-required') }}
                  </small>
                </div>
              </div>
            </div>

            <div class="row mt-4">
              <div class="col-12">
                <div class="form-group creation-buttons text-center">
                  <button
                    *ngIf="!stepInEditMode"
                    t-id="space-create-next-step-1-action"
                    type="button"
                    uiSref="work"
                    class="btn btn-solid btn-modal w-100"
                    (click)="nextStep(wizard)"
                    [disabled]="!form_step1.valid"
                  >
                    {{ t('btn-next') }}
                  </button>
                  <button
                    *ngIf="stepInEditMode"
                    t-id="space-create-next-step-2-action"
                    type="button"
                    uiSref="work"
                    class="btn btn-solid btn-modal w-100"
                    (click)="confirmEdit(wizard)"
                    [disabled]="!form_step1.valid"
                  >
                    {{ t('btn-confirm') }}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </aw-wizard-step>

        <aw-wizard-step class="wizard-step wizard-step_wrapper" [awOptionalStep]="true">
          <div class="creation-pic">
            <img
              src="assets/img/creation-avatar-{{
                config?.layout.variant === 'Light' ? 'light' : 'dark'
              }}.png"
              alt="avatar"
            />
          </div>
          <div class="creation-title">
            {{ t('title-add-avatar') }}
          </div>
          <form #form_step2="ngForm" class="editForm" novalidate>
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <div class="creation-naming">
                    <div class="creation-name">
                      <span [ngStyle]="{ background: color.value }">
                        {{ spaceName.value?.charAt(0).toUpperCase() }}
                      </span>
                      {{ spaceName.value }}
                    </div>
                    <div class="creation-color">
                      <ng-select
                        [items]="objectColors"
                        [(ngModel)]="pickedSpaceColor"
                        [clearable]="false"
                        ngModel
                        #color="ngModel"
                        class="creation-picker"
                        appendTo="body"
                        bindValue="color"
                        name="color"
                      >
                        <ng-template ng-label-tmp let-item="item">
                          <div
                            class="creation-color-preview"
                            [ngStyle]="{ background: item }"
                          ></div>
                        </ng-template>
                        <ng-template ng-option-tmp let-item="item">
                          <div class="creation-name creation-name_dropdown">
                            <span [ngStyle]="{ background: item }">
                              {{ spaceName.value?.charAt(0).toUpperCase() }}
                            </span>
                          </div>
                        </ng-template>
                      </ng-select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="creation-divider"></div>
            <div class="row">
              <span class="col-12 form-control-label">{{ t('form-set-avatar') }}</span>
              <div class="col-12 avatar-actions">
                <img
                  *ngIf="avatarImagePreview"
                  [src]="avatarImagePreview"
                  class="avatar-image-preview"
                  alt="Space avatar Image"
                />
                <div class="w-100 d-flex align-items-center button-wrapper">
                  <button class="btn btn-solid picker-icon" (click)="avatarImageInput.click()">
                    {{ t('btn-upload-photo') }}
                  </button>
                  <span
                    *ngIf="avatarImagePreview"
                    class="creation-remove"
                    (click)="deleteAvatarImage()"
                  >
                    {{ t('btn-remove') }}
                  </span>
                  <span class="button-hint">{{ t('btn-size-limit') }}</span>
                </div>
              </div>
            </div>
            <div class="creation-divider"></div>
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <span class="form-control-label">{{ t('form-select-default-avatar') }}</span>
                  <div class="creation-avatars">
                    <img
                      *ngFor="let avatar of defaultAvatars"
                      [src]="avatar"
                      class="avatar-image-preview"
                      alt="Space avatar Image"
                      (click)="setDefaultAvatar(avatar)"
                      t-id="space-create-default-avatar"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="row mt-3 mb-3">
              <div class="col-12">
                <div class="form-group d-flex">
                  <button
                    *ngIf="!stepInEditMode"
                    type="button"
                    uiSref="work"
                    class="btn btn-subtle mr-2 btn-modal"
                    (click)="previousStep(wizard)"
                    t-id="space-create-back-button"
                  >
                    {{ t('btn-back') }}
                  </button>
                  <button
                    *ngIf="!stepInEditMode"
                    t-id="space-create-next-step-1-action"
                    type="button"
                    uiSref="work"
                    class="btn btn-solid btn-modal"
                    (click)="nextStep(wizard)"
                  >
                    {{ t('btn-next') }}
                  </button>
                  <button
                    *ngIf="stepInEditMode"
                    t-id="space-create-next-step-1-action"
                    type="button"
                    uiSref="work"
                    class="btn btn-solid btn-modal w-100"
                    (click)="confirmEdit(wizard)"
                    [disabled]="!form_step1.valid"
                  >
                    {{ t('btn-confirm') }}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </aw-wizard-step>

        <aw-wizard-step class="wizard-step wizard-step_invite" [awOptionalStep]="true">
          <div class="creation-pic">
            <img
              src="assets/img/svg/invite-empty-state-{{
                config?.layout.variant === 'Light' ? 'light' : 'dark'
              }}.svg"
              class="image"
              alt="empty-state"
            />
          </div>
          <div class="creation-title">
            {{ t('title-invite-members') }}
          </div>
          <app-members-invite
            object="spaces"
            [platform]="platform"
            [objectCreating]="true"
            [usersInEditMode]="stepInEditMode"
            (inviteObjectMembers)="inviteSpaceMembers($event, wizard)"
          >
            <ng-container *ngIf="!stepInEditMode" previous-step-button>
              <button
                type="button"
                uiSref="work"
                class="btn btn-subtle mr-2 btn-modal"
                (click)="previousStep(wizard)"
                t-id="space-create-back-button-invite"
              >
                {{ t('btn-back') }}
              </button>
            </ng-container>
          </app-members-invite>
        </aw-wizard-step>

        <aw-wizard-step class="wizard-step wizard-step_invite">
          <div class="creation-title mb-3">
            {{ t('title-check-your-details') }}
          </div>
          <div class="creation-detail">
            {{ t('details-name') }}
            <div class="creation-detail_navigation creation-name" (click)="goToStep(0, wizard)">
              {{ spaceName.value }}
              <i class="ft-chevron-right ml-1"></i>
            </div>
          </div>
          <div class="creation-divider"></div>

          <div class="creation-detail">
            {{ t('details-avatar') }}
            <div class="creation-detail_navigation creation-name" (click)="goToStep(1, wizard)">
              <img
                *ngIf="avatarImagePreview"
                [src]="avatarImagePreview"
                class="creation-detail_avatar mr-1"
                alt="Space avatar Image"
              />
              <span *ngIf="!avatarImagePreview" [ngStyle]="{ background: color.value }">
                {{ spaceName.value?.charAt(0).toUpperCase() }}
              </span>
              <i class="ft-chevron-right"></i>
            </div>
          </div>
          <div class="creation-divider"></div>

          <div class="creation-detail">
            {{ t('details-teammates') }}
            <div class="creation-detail_navigation" (click)="goToStep(2, wizard)">
              <span class="creation-detail_edit">{{ t('btn-edit') }}</span>
            </div>
          </div>
          <div class="creation-members">
            <div class="creation-members_user" *ngFor="let member of invitedUsersPreview">
              <div class="creation-members_info">
                <i
                  *ngIf="member.isExternalUser"
                  class="fa fa-envelope-o envelope-icon"
                  aria-hidden="true"
                ></i>
                <app-avatar
                  *ngIf="!member.isExternalUser"
                  [userId]="member._id"
                  [width]="40"
                  [height]="40"
                  [showStatus]="false"
                >
                </app-avatar>
                <div>
                  <div class="creation-members_name">
                    {{ member.userName | limitTo: 20 }}
                  </div>
                  <div class="creation-members_email">
                    {{ member.email | limitTo: 20 }}
                  </div>
                </div>
              </div>
              <div class="creation-members_role">
                {{ member.roleName }}
              </div>
            </div>
          </div>

          <div class="row mt-4 mb-3">
            <div class="col-12">
              <div class="form-group d-flex">
                <button
                  type="button"
                  uiSref="work"
                  class="btn btn-subtle mr-2 btn-modal"
                  (click)="previousStep(wizard)"
                  t-id="space-create-back-button-create"
                >
                  {{ t('btn-back') }}
                </button>
                <button
                  t-id="space-create-button"
                  type="button"
                  uiSref="work"
                  class="btn btn-solid btn-modal"
                  [disabled]="isLoading"
                  (click)="createSpace(form_step1, form_step2)"
                >
                  {{ t('btn-create') }}
                </button>
              </div>
            </div>
          </div>
        </aw-wizard-step>
      </aw-wizard>
    </section>
  </div>
</ng-container>
<input
  #avatarImageInput
  type="file"
  style="display: none"
  accept=".jpg, .jpeg, .png"
  (change)="avatarChangeEvent($event)"
/>
