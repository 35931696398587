import { Injectable, NgZone } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { tap } from 'rxjs/operators';

import { NotificationsStateModel } from '../models/NotificationsState';
import {
  MarkNotificationAsRead,
  NotificationSse,
  NotificationsSetIsPlaying,
  NotificationsSetPlayRingtone,
  PushNotificationsSettingsGet,
} from '../actions/notifications.action';
import { PushNotificationsSettingsCreate } from '../actions/notifications.action';
import { NotificationsGet } from '../actions/notifications.action';
import { PushNotificationsService } from '../../../api/services/push-notifications.service';
import { NotificationsService } from '../../../api/services/notifications.service';
import { Observable, lastValueFrom, of } from 'rxjs';

import { LocalStorageService } from 'ngx-localstorage';
import { SocketsService } from '../../../../app/shared/services/sockets.service';
import { WrappedSocket } from 'ngx-socket-io/src/socket-io.service';

@State<NotificationsStateModel>({
  name: 'Notifications',
  defaults: {
    filters: {}, // filter: "ALL ACTIONS" | "MENTIONS" | "NO CALLS" | "NOTHING", noRingtone: boolean
    needPlayRingtone: false,
    isPlaying: false,
    notifications: [],
  },
})
@Injectable()
export class NotificationsState {
  @Selector()
  static getUnreadMessages(state: NotificationsStateModel) {
    return state.notifications.filter((notification) => notification.unread === true);
  }

  @Selector()
  static getUnreadMessagesCount(state: NotificationsStateModel) {
    return state.notifications.filter((notification) => notification.unread === true).length || 0;
  }

  /**
   * get filter
   * @param  {NotificationsStateModel} state
   */
  @Selector()
  static getFilter(state: NotificationsStateModel) {
    return (chatId: string) => state.filters[chatId];
  }

  /**
   * get needPlayRingtone
   * @param  {NotificationsStateModel} state
   */
  @Selector()
  static getNeedPlayRingtone(state: NotificationsStateModel) {
    return state.needPlayRingtone;
  }

  /**
   * get isPlaying
   * @param  {NotificationsStateModel} state
   */
  @Selector()
  static getIsPlaying(state: NotificationsStateModel) {
    return state.isPlaying;
  }

  /**
   * get notifications (for user-related actions)
   * @param  {NotificationsStateModel} state
   */
  @Selector()
  static getNotifications(state: NotificationsStateModel) {
    return state.notifications || [];
  }

  private socket: WrappedSocket;

  constructor(
    private pushNotificationsService: PushNotificationsService,
    private notificationsService: NotificationsService,
    private store: Store,
    private zone: NgZone,
    private readonly localStorageService: LocalStorageService,
    private socketsService: SocketsService,
  ) {
    this.socket = this.socketsService.get();

    this.socket.fromEvent('new:notification').subscribe((data: any) => {
      this.store.dispatch(new NotificationSse({ data }));
    });
  }

  /**
   * Get projects action handler
   * @param {getState, setState}: StateContext<NotificationsStateModel>
   * @param action
   */
  @Action(PushNotificationsSettingsGet)
  get_notifications_filters(
    { getState, patchState }: StateContext<NotificationsStateModel>,
    action: PushNotificationsSettingsGet,
  ) {
    const { filters } = getState();

    return this.pushNotificationsService.notificationFiltersGet(action.payload).pipe(
      tap(
        (res) => {
          const chatId = res?.chatId || res?.objectId;

          if (res && chatId) {
            patchState({
              filters: {
                ...filters,
                [chatId]: {
                  filter: res.filter || null,
                  noRingtone: res.noRingtone || null,
                },
              },
            });
          }
          return res;
        },
        (err) => {
          throw err.error;
        },
      ),
    );
  }

  /**
   * Create push notifications filter action handler
   * @param  {getState, patchState}: StateContext<NotificationsStateModel>
   * @param  {PushNotificationsSettingsCreate} action
   */
  @Action(PushNotificationsSettingsCreate)
  pn_settings_create(
    { getState, patchState }: StateContext<NotificationsStateModel>,
    action: PushNotificationsSettingsCreate,
  ) {
    const { filters } = getState();
    return this.pushNotificationsService.notificationFiltersCreate({ body: action.payload }).pipe(
      tap(
        (res) => {
          patchState({
            filters: {
              ...filters,
              [res.chatId]: {
                filter: res.filter || null,
                noRingtone: res.noRingtone || false,
              },
            },
          });

          return res;
        },
        (err) => {
          throw err.error;
        },
      ),
    );
  }

  /**
   * Change video call needPlayRingtone handler
   * @param  stateContext: StateContext<NotificationsStateModel>
   * @param  {NotificationsSetPlayRingtone} action
   */
  @Action(NotificationsSetPlayRingtone)
  set_need_play_ringtone(
    { patchState }: StateContext<NotificationsStateModel>,
    action: NotificationsSetPlayRingtone,
  ) {
    return patchState({ needPlayRingtone: action.payload.needPlay });
  }

  /**
   * Change isPlaying handler
   * @param  stateContext: StateContext<NotificationsStateModel>
   * @param  {NotificationsSetIsPlaying} action
   */
  @Action(NotificationsSetIsPlaying)
  set_is_playing(
    { patchState }: StateContext<NotificationsStateModel>,
    action: NotificationsSetIsPlaying,
  ) {
    return patchState({ isPlaying: action.payload });
  }

  /// NEW SYSTEM START
  /**
   * Get notifications action handler
   * @param  {patchState}: StateContext<NotificationsStateModel>
   * @param  {NotificationsGet} action
   */
  @Action(NotificationsGet)
  async notificationsGet(
    { patchState }: StateContext<NotificationsStateModel>,
    { payload }: NotificationsGet,
  ) {
    const notificationsList = await lastValueFrom(
      this.notificationsService.notificationsGetList(payload.page, payload.limit, payload.unread),
    );

    if (notificationsList['data'] && notificationsList['data'].length > 0) {
      const sortedNotifications = notificationsList['data'].sort((a, b) => {
        return b._id.localeCompare(a._id);
      });

      patchState({ notifications: sortedNotifications });
    }
  }

  @Action(NotificationSse)
  async notificationSse(
    { patchState, getState }: StateContext<NotificationsStateModel>,
    { payload }: NotificationSse,
  ) {
    const state = getState();

    patchState({
      notifications: [payload.data.data, ...(state.notifications || [])],
    });
  }

  @Action(MarkNotificationAsRead)
  async markNotificationAsRead(
    { getState, patchState }: StateContext<NotificationsStateModel>,
    { payload }: MarkNotificationAsRead,
  ) {
    let updatedNotifications;

    if (payload && payload.notificationId) {
      lastValueFrom(
        this.notificationsService.markNotificationsAsRead(payload.notificationId.toString()),
      );

      updatedNotifications = getState().notifications.filter((notification) => {
        return notification._id !== payload.notificationId;
      });
    } else {
      lastValueFrom(this.notificationsService.markNotificationsAsRead());

      updatedNotifications = getState().notifications.map((notification) => {
        return { ...notification, unread: false };
      });
    }

    patchState({ notifications: updatedNotifications });
  }
}
