import { Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngxs/store';

import { AuthState } from '../../shared/store/states/auth.state';
import { ProjectsState } from '../../shared/store/states/projects.state';

@Pipe({
  name: 'checkPermission',
  standalone: true,
})
export class CheckPermissionPipe implements PipeTransform {
  constructor(private store: Store) {}

  /**
   * Check permission of specified operation based on auth user info
   *
   *  @param value:string = 'object::objectId::operationId'
   *
   * where
   * object = object name (spaces, projects, etc)
   * objectId = special object id (can be null for some actions)
   * operationId = operation id (spaceGetList, spaceCreate etc)
   */
  public transform(value) {
    const [object, objectId, operationId] = value.split('::');
    const allPermissions = this.store.selectSnapshot(AuthState.getPermissions);
    const roleMember = this.store.selectSnapshot(AuthState.getRoles);

    let roles = [...roleMember];
    if (object && object !== 'users' && objectId) {
      roles = roleMember.filter((item) => item.object === object && item.objectId === objectId);
    }

    if (!roles.length) {
      roles = roleMember.filter((item) => item.roleName === 'Owner');
    }

    let spaceId;
    if (!roles.length && object === 'projects' && objectId) {
      const project = this.store
        .selectSnapshot(ProjectsState.getLoadedProjects)
        .filter((item) => item._id === objectId);
      if (project.length) {
        roles = roleMember.filter(
          (item) => item.object === 'spaces' && item.objectId === project[0].spaceId,
        );
        spaceId = project[0].spaceId;
      }
    }

    const rolesSet = [...new Set(roles.map(({ roleName }) => roleName))];

    const currentPermission = allPermissions.filter((item: any) => {
      if (item.operationId === operationId && rolesSet.includes(item.roleName)) {
        const ownerCheck =
          item.roleName === 'Owner' && item.object === object && item.objectId === null;
        const commonCheck = item.object === object && item.objectId === objectId;
        const nestedProjectCheck =
          spaceId &&
          ((item.object === 'spaces' && item.objectId === spaceId) ||
            (item.object === 'projects' && item.objectId === null && item.spaceId === spaceId));
        const usersCheck = item.object === 'users' && object === 'users';

        return ownerCheck || commonCheck || nestedProjectCheck || usersCheck;
      }
      return false;
    });

    return !!currentPermission.length;
  }
}
